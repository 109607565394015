import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import enterOfferBlue from "../assets/enterOfferBlue.png";
import { Link, Redirect } from "react-router-dom";
import withSizes from "react-sizes";
import Fade from "react-reveal/Fade";
import { HelpModal, ErrorSnackbar } from "../components";
import { userfb } from "../firebase";
import { Button, CssBaseline, TextField, Grid, LinearProgress, Checkbox, Typography, CircularProgress } from "@material-ui/core";

const userDB = userfb.database();
const auth = userfb.auth();

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "2em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	root: {
		".MuiFormControlLabel-label": {
			fontSize: "0.9em",
			fontWeight: "700",
			color: "#7f7f7f",
			margin: "0",
			display: "inline",
		},
		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
	},
}));

function NewSignup(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [values, setValues] = useState({
		email: "",
		password: "",
		lastName: "",
		firstName: "",
		errorType: 0,
		errorOpen: false,
		errorMessage: "",
		signUpSuccess: false,
		flag: false,
		checked: false,
		openTerms: false,
		loading: false,
	});

	const [errors, setErrors] = useState({
		email: false,
		password: false,
		lastName: false,
		firstName: false,
	});

	const handleChange = (name) => (event) => {
		if (name === "checked") {
			setValues({ ...values, [name]: event.target.checked });
		} else {
			setValues({ ...values, [name]: event.target.value });
		}
	};

	const signup = (event) => {
		event.preventDefault();
		var e = {
			email: false,
			password: false,
			lastName: false,
			firstName: false,
		};
		if (values.email === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			e.email = true;
		}
		if (values.lastName === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			e.lastName = true;
		}
		if (values.firstName === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			e.firstName = true;
		}
		if (values.password === "") {
			setValues({ ...values, errorOpen: true, errorType: 2 });
			e.password = true;
		} else if (!values.checked) {
			setValues({ ...values, errorOpen: true, errorType: 1 });
		} else {
			setValues({ ...values, loading: true });
		}
		if (e.email || e.password || e.firstName || e.lastName || !values.checked) {
			setErrors(e);
			return;
		}
		auth.createUserWithEmailAndPassword(values.email, values.password)
			.then(async (user) => {
				console.log(user.user.uid);
				await userDB
					.ref(`users/${user.user.uid}/meta`)
					.set({
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						isNewUser: true,
						anonymous: false,
						marketplace: true,
					})
					.then(() => {
						if (user) {
							setValues({ ...values, loading: false, isSignedIn: !!user });
						}
					});
			})
			.catch((e) => {
				if (e.code === "auth/email-already-in-use") {
					setValues({ ...values, errorOpen: true, errorType: 3 });
				} else if (e.code === "auth/invalid-email") {
					setValues({ ...values, errorOpen: true, errorType: 4 });
				} else if (e.code === "auth/operation-not-allowed") {
					setValues({ ...values, errorOpen: true, errorType: 5 });
				} else if (e.code === "auth/weak-password") {
					setValues({ ...values, errorOpen: true, errorType: 6 });
				} else {
					setValues({ ...values, errorOpen: true, errorType: 0 });
				}
				console.error(e);
			});
	};

	const callbackCloseError = () => {
		setValues({ ...values, errorOpen: false });
	};
	const handleClickOpenTerms = () => {
		setValues({ ...values, openTerms: true });
	};

	if (!values.isSignedIn) {
		return (
			<div style={{ marginTop: "10em" }}>
				<CssBaseline />
				<ErrorSnackbar
					errorType={values.errorType}
					stripeError={values.errorMessage}
					open={values.errorOpen}
					parentCallbackCloseError={callbackCloseError}
				/>
				<Grid
					container
					alignItems='center'
					spacing={0}
					direction='column'
					style={{
						marginBottom: "1em",
					}}>
					<Fade duration={props.from === "modal" ? 300 : 1000}>
						<div
							style={{
								width: "25em",
								backgroundColor: "#fff",
								border: "1px solid #f6f6f8",
								boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								position: "fixed",
								top: "10%",
								padding: "3.75em 3.125em 4.375em 3.125em",
							}}>
							{/* <a href='https://enteroffer.com/'> */}
							<img
								src={enterOfferBlue}
								alt='EnterOffer'
								style={{
									width: "150px",
									display: "block",
									marginLeft: "auto",
									marginRight: "auto",
									marginBottom: "2.5em",
									marginTop: "-1.25em",
								}}
							/>

							{/* </a> */}
							<h2
								style={{
									textAlign: "center",
									paddingLeft: "0.625em",
									paddingRight: "0.625em",
									fontSize: "1.2em",
									fontWeight: "800",
									color: "#3e3e3e",
									textTransform: "uppercase",
									letterSpacing: "1",
								}}>
								SIGN UP
							</h2>

							<form className={classes.form} noValidate>
								<Grid container alignItems='center' spacing={2}>
									<Grid item xs={12} sm={6}>
										<TextField
											error={errors.firstName}
											className={classes.root}
											autoComplete='fname'
											name='firstName'
											variant='outlined'
											onChange={handleChange("firstName")}
											fullWidth
											id='firstName'
											label='First Name'
											margin='normal'
											autoFocus
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											error={errors.lastName}
											className={classes.root}
											variant='outlined'
											fullWidth
											onChange={handleChange("lastName")}
											id='lastName'
											label='Last Name'
											name='lastName'
											margin='normal'
											autoComplete='lname'
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={errors.email}
											margin='normal'
											className={classes.root}
											fullWidth
											id='email'
											onChange={handleChange("email")}
											label='Email'
											name='email'
											autoComplete='email'
											variant='outlined'
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											error={errors.password}
											margin='normal'
											className={classes.root}
											fullWidth
											onChange={handleChange("password")}
											name='password'
											label='Password'
											type='password'
											id='password'
											variant='outlined'
										/>
									</Grid>
									<HelpModal openTerms={values.openTerms} handleClose={() => setValues({ ...values, openTerms: false })} />
									<Checkbox checked={values.checked} onChange={handleChange("checked")} value='checked' color='primary' />
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: "700",
											color: "#7f7f7f",
											margin: "0",
											display: "inline",
										}}>
										Agree to the{" "}
										<u onClick={handleClickOpenTerms} style={{ cursor: "pointer" }}>
											terms and conditions
										</u>
									</Typography>
									<Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit} onClick={signup}>
										{!values.loading ? "Sign Up" : <CircularProgress size={24} style={{ color: theme.palette.secondary.main }} />}
									</Button>
								</Grid>
							</form>
							<p
								style={{
									marginTop: "2em",
									textAlign: "center",
									cursor: "pointer",
								}}
								onClick={props.from ? props.callback : null}>
								<Link
									variant='body2'
									to={{
										pathname: props.from === "modal" ? "/" : "/signin",
										// search: props.location.search
									}}
									style={{
										textDecoration: "none",
										color: theme.palette.primary.main,
										fontSize: "1em",
									}}>
									Already have an account? Sign in!
								</Link>
							</p>
							{values.flag ? <LinearProgress /> : null}
						</div>
					</Fade>
				</Grid>
			</div>
		);
	} else {
		if (window.location.pathname === "/signup") {
			return (
				<Redirect
					to={{
						pathname: "/",
						search: window.location.search,
					}}
				/>
			);
		} else {
			props.handleClose();
			return null;
		}
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(NewSignup);
