import { auth, userfb } from "./firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);

// Sign out
export const signOut = async () => {
	return await auth
		.signOut()
		.then(() => {
			return true;
		})
		.catch((e) => {
			console.error(e);
			return false;
		});
};

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) => auth.currentUser.updatePassword(password);

export const isSignedIn = () => {
	console.log(auth.currentUser !== null && auth.currentUser !== undefined);
	return auth.currentUser !== null && auth.currentUser !== undefined;
};
