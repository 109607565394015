import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withSizes from "react-sizes";
import _ from "lodash";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

const headCells = [
	{ id: "offer", numeric: false, disablePadding: true, label: "Offer" },
	{ id: "location", numeric: true, disablePadding: false, label: "Location" },
	{ id: "condition", numeric: true, disablePadding: false, label: "Condition" }
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "default"} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel style={{ fontWeight: 700 }} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: 0,
		paddingRight: 0
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
			  },
	title: {
		flex: "1 1 100%",
		fontWeight: 700
	}
}));

const EnhancedTableToolbar = props => {
	const classes = useToolbarStyles();

	return (
		<Toolbar className={classes.root}>
			<Typography className={classes.title} variant='body2' id='tableTitle' component='div'>
				BUYERS
			</Typography>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 250
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1
	},

	chips: {
		display: "inline",
		marginRight: 8,
		padding: 8,
		fontSize: 14,
		fontWeight: 600
	},
	chipIcon: {
		verticalAlign: "bottom",
		fontSize: 22
	},
	sell: {
		color: "#75c24d",
		fontWeight: 600,
		fontSize: 16,
		cursor: "pointer"
	},
	buy: {
		color: "#58c8f5",
		fontWeight: 600,
		fontSize: 16,
		cursor: "pointer"
	},
	tableRow: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#75c24d22 !important"
		}
	}
}));

function numberWithCommas(x) {
	return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("condition");
	const [selected, setSelected] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		let r = [];
		if (!_.isEmpty(props.sellOffers)) {
			props.sellOffers.map(o => {
				return r.push(o);
			});
			r = r.sort((a, b) => (a.offer < b.offer ? 1 : -1));
		}
		setRows(r);
	}, [props.sellOffers]);

	// useEffect(() => {
	// 	setSelected([]);
	// }, [props.open]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleClick = (event, row, index) => {
		props.selected(row);
		props.openEOI(row);
		setSelected(index);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	return (
		<div className={classes.root}>
			{_.isEmpty(rows) ? (
				<Paper className={classes.paper} elevation={0}>
					No Sell Offers yet.
				</Paper>
			) : (
				<Paper className={classes.paper} elevation={0}>
					<EnhancedTableToolbar numSelected={selected} />
					<TableContainer>
						<Table className={classes.table} size='small' style={{ zoom: props.isMobile ? 0.9 : null }}>
							<EnhancedTableHead numSelected={selected} classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
							<TableBody id='sellTable'>
								{stableSort(rows, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = selected === index;
										const labelId = `enhanced-table-checkbox-${index}`;
										return (
											<TableRow
												hover
												className={classes.tableRow}
												onClick={event => handleClick(event, row, index)}
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={index}
												selected={isItemSelected}>
												<TableCell component='th' id={labelId} scope='row' padding='none'>
													{numberWithCommas(row.offer)}
												</TableCell>
												<TableCell align='left'>{row.location ? <p className={classes.chips}>{row.location}</p> : null}</TableCell>
												<TableCell align='left'>
													<p className={classes.chips}>{row.condition}</p>
												</TableCell>
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 33 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component='div'
						style={rows.length <= rowsPerPage ? { display: "none" } : { zoom: props.isMobile ? 0.8 : null }}
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			)}
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(EnhancedTable);
