import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import withSizes from "react-sizes";
import { Signin, Signup } from "../pages";

function SignInModal(props) {
	const [open, setOpen] = useState(false);
	const [flag, setFlag] = useState(false);
	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	const handleClose = () => {
		props.closeModal();
		setOpen(false);
	};
	const handleModal = () => {
		setFlag(!flag);
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} style={{ width: props.isMobile ? null : 500, margin: "auto" }}>
				{!flag ? (
					<Signin from={"modal"} callback={handleModal} handleClose={handleClose} />
				) : (
					<Signup from={"modal"} callback={handleModal} handleClose={handleClose} />
				)}
			</Dialog>
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(SignInModal);
