import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import withSizes from "react-sizes";

function Transition(props) {
	return <Slide direction='up' {...props} />;
}

function HelpModal(props) {
	const handleCloseCancel = () => {
		props.handleClose();
	};

	return (
		<div style={{ zIndex: "1000", fontFamily: "inherit" }}>
			{props.openTerms ? (
				<Dialog open={props.openTerms} TransitionComponent={Transition} onClose={handleCloseCancel}>
					<DialogTitle style={{ textAlign: "center" }}>{"TERMS OF USE"}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<b>
								Welcome to EnterOffer! We are excited to have you on board! However, to register to our free service, you will need to agree to our user agreement. This document will
								specify rules relating to use, rights and more.
							</b>{" "}
							<br /> <br />
							<strong>Definitions:</strong>
							<br /> <br />
							User: You, the person using the service <br />
							We: EnterOffer/Us <br />
							Habitual: At least 5 offers with the majority of discounts greater than 80% <br />
							<br />
							<br />
							<strong>1. EnterOffer Rights</strong>
							<br />
							<br />
							1.1 EnterOffer reserves the right to:
							<br />
							<br />
							1.1.1 Ban, whether permanently or temporarily any person that abuses our service, or at our own discretion;and <br />
							1.1.2 Modify/change/delete any offer made by any persons; and <br />
							1.1.3 Require users to agree to any revised user agreement; and <br />
							1.1.4 Require users to provide additional information; and <br />
							1.1.5 Send promotional material to users
							<br />
							<br />
							1.2 For the purposes of 1.1.1, abuse of service includes, but is not limited to:
							<br />
							<br />
							1.2.1 Habitual offers with discount of 80%+; <br />
							1.2.3 Users who steal, attempt to steal, copy, attempt to copy, use or attempt to use our intellectual property
							<br />
							<br />
							<br />
							<strong>2. Intellectual property</strong>
							<br />
							<br />
							2.1 By using the EnterOffer service, you agree that all software and ideas are the intellectual property of EnterOffer Pty Ltd <br />
							2.2 By using the EnterOffer service, you acknowledge that EnterOffer owns all content already displayed website or input by users <br />
							2.3 By using the EnterOffer service, you agree to allow EnterOffer to utilise all user content in accordance with our privacy policy
							<br />
							<br />
							<br />
							<strong>3. Clauses</strong>
							<br />
							<br />
							3.1 The user is contractually obliged to purchase any accepted offer <br />
							3.2 EnterOffer does not agree to ensure the availability of any product at any stage of the offer process <br />
							3.3 It is not the responsibility of EnterOffer to resolve any and all problems regarding a product purchased through the EnterOffer service. Any and all problems with a
							product are to be resolved between the user and the retailer
							<br />
							3.4 EnterOffer does not warrant for any return policy. Returns will work accordingly with the policy of the retailer the user has purchased from
							<br />
							3.5 EnterOffer will not charge the user unless the offer is accepted
							<br />
							<br />
							<br />
							<strong>4. Terms of Use</strong>
							<br />
							<br />
							4.1 The user must be at least 18 years of age <br />
							4.2 The user must not publicise - through any medium - the price of any offer <br />
							4.3 The user may not pay with any method of payment not belonging to themselves <br />
							4.4 The user will have to provide payment details upon making an offer <br />
							<br />
							<br />
							For issues or inquiries contact the EnterOffer team at <strong>hello@enteroffer.com</strong>
							<br />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseCancel} color='primary' autoFocus>
							Okay
						</Button>
					</DialogActions>
				</Dialog>
			) : props.openPrivacy ? (
				<Dialog open={props.openPrivacy} TransitionComponent={<Slide direction='up' />} onClose={handleCloseCancel}>
					<DialogTitle style={{ textAlign: "center" }}>{"PRIVACY POLICY"}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<b>By using this service, you agree to the privacy policy found at enteroffer.com/privacy</b> <br /> <br />
							By using EnterOffer, you, the consumer, agree to all terms of service provided below. <br />
							<br />
							<b>Our Intellectual Property</b> <br />
							Our website and service are the exclusive property of EnterOffer. Information provided by our website are not intended to create legally binding assurances. Any information
							garnered by you, the consumer from our website is subject to regular change by us - EnterOffer. You must not copy, imitate, modify, alter, amend or use without our prior
							written consent any URLs representing our Website, or any of our content, logos, graphics, icons or other content published on our Website or in our printed media. <br />
							<br />
							<b>Limitation of Liabilities</b> <br />
							To the full extent permitted by law: <br />
							EnterOffer’s liability for all claims arising under or related in any way to this Agreement no matter how arising, and whether in contract, tort (including negligence), or
							otherwise, will not exceed:(A) $500; or, if that limit is found to be unenforceable; $1,000; or, if that limit is found to be unenforceable; the total value of any affected
							Order(s); and EnterOffer and its related bodies corporate, or any third party providing services on behalf of EnterOffer, will not be liable to you for any Consequential
							Loss arising under or related in any way to this Agreement no matter how arising and whether in contract, tort (including negligence) or otherwise. <br />
							<br />
							EnterOffer and its related bodies corporate, or any third party providing services on behalf of EnterOffer, and the directors, employees, officers, agents and
							representatives of them, will not, to the extent permitted by law, be liable for any loss or damage (including any direct, indirect or Consequential Loss or damage) you or
							any third party may incur from your purchase or use of any goods or services from a Retailer, except to the extent that we cause such loss or damage (in which case, our
							liability to you is limited as set out in clause 5(a)(i) above). <br />
							<br />
							You acknowledge that any consumer-related claims you may have in respect of the goods or services purchased from a Retailer will be a matter between you and the Retailer.
							To the extent permitted by law, a party’s liability to the other party under or in connection with this Agreement is reduced to the extent, if any, to which the other
							party’s acts or omissions cause or contribute to its own loss or damage. <br />
							<br />
							The parties will take all reasonable steps to mitigate any loss incurred by them under this Agreement. <br />
							<br />
							<b>What EnterOffer does not assure</b> <br />
							EnterOffer does not ensure that any offers made through our website will reach the corresponding retailer. <br />
							In the occurrence that a offers reaches the corresponding retailer, EnterOffer does not ensure a response from the retailer In the occurrence that a retailer responds to a
							offer, EnterOffer does not ensure that: <br />
							The offer will be accepted; or The offer will be rejected; or That any transaction will consequently follow <br />
							<br />
							In the occurrence that a retailer wishes to accept a offer, EnterOffer does not ensure the materialisation of that transaction and any disputes resulting, whether it has to
							do with acceptance or price or any other matter is between the consumer and retailer. <br />
							<br />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseCancel} color='primary' autoFocus>
							Okay
						</Button>
					</DialogActions>
				</Dialog>
			) : null}
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(HelpModal);
