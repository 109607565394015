import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import withSizes from "react-sizes";
import { Link, Redirect } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Button, CssBaseline, TextField, Grid, CircularProgress } from "@material-ui/core";
import { userfb } from "../firebase";
import { ErrorSnackbar } from "../components";

const auth = userfb.auth();

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "1em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},

	root: {
		marginTop: 4,
		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
	},
}));

function Signin(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [values, setValues] = useState({
		email: "",
		password: "",
		loading: false,
		errorType: 0,
		errorOpen: false,
		errorMessage: "",
		isSignedIn: false,
	});

	const handleChange = (name) => (event) => {
		setValues({ ...values, [name]: event.target.value });
	};

	const signin = (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		auth.signInWithEmailAndPassword(values.email, values.password)
			.then((user) => {
				console.log(user);
				setValues({ ...values, loading: false, isSignedIn: !!user });
			})
			.catch((e) => {
				if (e.code === "auth/user-disabled") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 7 });
				} else if (e.code === "auth/invalid-email") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 4 });
				} else if (e.code === "auth/user-not-found") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 8 });
				} else if (e.code === "auth/wrong-password") {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 8 });
				} else {
					setValues({ ...values, loading: false, errorOpen: true, errorType: 0 });
				}
				console.error(e);
			});
	};

	const callbackCloseError = (param) => {
		setValues({ ...values, errorOpen: false });
	};

	if (!values.isSignedIn) {
		return (
			<div style={{ marginTop: props.from === "modal" ? null : "10em" }}>
				<CssBaseline />
				<ErrorSnackbar
					errorType={values.errorType}
					stripeError={values.errorMessage}
					open={values.errorOpen}
					parentCallbackCloseError={callbackCloseError}
				/>
				<Grid
					container
					alignItems='center'
					spacing={0}
					direction='column'
					style={{
						marginBottom: props.from === "modal" ? null : "1em",
					}}>
					<Fade duration={props.from === "modal" ? 300 : 1000}>
						<div
							style={{
								width: props.from === "modal" ? null : "25em",
								backgroundColor: "#fff",
								border: "1px solid #f6f6f8",
								boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								position: props.from === "modal" ? null : "fixed",
								top: props.from === "modal" ? null : "10%",
								padding: "3.75em 3.125em 4.375em 3.125em",
							}}>
							<img
								src={"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"}
								alt='enteroffer'
								style={{
									width: "180px",
									display: "block",
									marginLeft: "auto",
									marginRight: "auto",
									marginTop: "-1.25em",
								}}
							/>
							<p
								style={{
									textAlign: "center",
									margin: 0,
									fontSize: "1em",
									fontWeight: 600,
								}}>
								Marketplace
							</p>
							<br />
							<form className={classes.form} noValidate>
								<TextField
									margin='normal'
									className={classes.root}
									fullWidth
									id='email'
									onChange={handleChange("email")}
									label='Email'
									name='email'
									autoComplete='email'
									autoFocus
									variant='outlined'
								/>
								<TextField
									margin='normal'
									className={classes.root}
									fullWidth
									onChange={handleChange("password")}
									name='password'
									label='Password'
									type='password'
									id='password'
									variant='outlined'
								/>
								<Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit} onClick={signin}>
									{!values.loading ? "Sign In" : <CircularProgress size={24} style={{ color: theme.palette.secondary.main }} />}
								</Button>
							</form>
							<p
								style={{
									marginTop: "1em",
									textAlign: "center",
									cursor: "pointer",
								}}
								onClick={props.from ? props.callback : null}>
								<Link
									variant='body2'
									to={{
										pathname: props.from === "modal" ? "/" : "/signup",
										// search: props.location.search,
									}}
									style={{
										textDecoration: "none",
										color: theme.palette.primary.main,
										fontSize: "1em",
									}}>
									Don't have an account? Sign up!
								</Link>
							</p>
							<br />
							<br />
							<br />
						</div>
					</Fade>
				</Grid>
			</div>
		);
	} else {
		if (window.location.pathname === "/signin") {
			return (
				<Redirect
					to={{
						pathname: "/",
						search: window.location.search,
					}}
				/>
			);
		} else {
			props.handleClose();
			return null;
		}
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(Signin);
