import firebase from "firebase/app";
import "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

const websiteConfig = {
	apiKey: "AIzaSyAzQSf91bn5EfHcd3fCTrDItYhBu7l6LLg",
	authDomain: "oaklas-website.firebaseapp.com",
	databaseURL: "https://oaklas-website.firebaseio.com",
	projectId: "oaklas-website",
	storageBucket: "oaklas-website.appspot.com",
	messagingSenderId: "773320995457",
	appId: "1:773320995457:web:e0ab05609f96a0ffd21380",
	measurementId: "G-3MMB3EZF4Z",
};
const userConfig = {
	apiKey: "AIzaSyB5rSaDR8wATQh7XcppVYpv5A3cILnwjNo",
	authDomain: "oaklas-user.firebaseapp.com",
	databaseURL: "https://oaklas-user.firebaseio.com",
	projectId: "oaklas-user",
	storageBucket: "oaklas-user.appspot.com",
	messagingSenderId: "282663992239",
};

const websitefb = firebase.initializeApp(websiteConfig);
const userfb = firebase.initializeApp(userConfig, "user");
const auth = userfb.auth();
var analytics = null;
var perf = null;
if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
	analytics = websitefb.analytics();
	perf = websitefb.performance();
}

// userfb.functions().useFunctionsEmulator("http://localhost:5000");
export { websitefb, userfb, analytics, perf, auth };
