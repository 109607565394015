import { websitefb, auth, userfb } from "./firebase";
import { CodeSharp } from "@material-ui/icons";

export function submitInterest(name, email, date, location, btn) {
	websitefb
		.database()
		.ref("marketplace")
		.push()
		.set({
			submittedDate: date,
			name: name,
			email: email,
			subject: "Marketplace EOI",
			location: location,
			button: btn,
		})
		.then((x) => {
			return;
		})
		.catch((e) => {
			console.error(e);
		});
}

export const initials = async () => {
	if (auth.currentUser === null || auth.currentUser === undefined) {
		return null;
	} else
		return await userfb
			.database()
			.ref(`users/${auth.currentUser.uid}/meta`)
			.once("value")
			.then((res) => {
				console.log(`${res.val().firstName} ${res.val().lastName}`);

				return (
					res
						.val()
						.firstName.substring(0, 1)
						.toUpperCase() +
					res
						.val()
						.lastName.substring(0, 1)
						.toUpperCase()
				);
			})
			.catch(console.error);
};

export function getMeta() {
	return new Promise(function(resolve, reject) {
		var uid = auth.currentUser.uid;
		userfb
			.database()
			.ref(`/users/${uid}/meta`)
			.once("value", (snapshot) => {
				return resolve(snapshot.val());
			})
			.catch((e) => {
				return reject(e);
			});
	});
}
