import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { NotificationsNoneRounded, PriorityHigh } from "@material-ui/icons";
import { Button, Badge, ListItemIcon, Typography, Menu, MenuItem } from "@material-ui/core";

const options = ["None", "Atria", "Callisto", "Dione", "Ganymede", "Hangouts Call", "Luna", "Oberon", "Phobos", "Pyxis", "Sedna", "Titania", "Triton", "Umbriel"];

const useStyles = makeStyles(theme => ({
	navLinks: {
		textTransform: "capitalize",
		padding: "0.5rem 1rem",
		// display: "inline-flex",
		transition: "0.3s",
		marginRight: "20px",
		"&:hover": {
			color: "#000 !important",
			backgroundColor: "transparent !important"
		}
	}
}));
const StyledBadge = withStyles(theme => ({
	badge: {
		right: 5,
		top: 7,
		zoom: 0.75,
		height: 20,
		minWidth: 18,
		fontSize: 12,
		fontWeight: 600
	}
}))(Badge);

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			{/* <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick} style={{ color: props.clr }}>
				<NotificationIcon />
			</IconButton> */}
			<Button className={classes.navLinks} style={{ color: props.clr }} onClick={handleClick}>
				<StyledBadge badgeContent={options.length}>
					<NotificationsNoneRounded />
				</StyledBadge>
			</Button>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 6.5,
						width: "30ch"
					}
				}}>
				{options.map(option => (
					<MenuItem key={option} selected={option === option[0]} onClick={handleClose}>
						<ListItemIcon>
							<PriorityHigh color='error' fontSize='small' />
						</ListItemIcon>
						<Typography variant='inherit' noWrap>
							{option}
						</Typography>
					</MenuItem>
				))}
			</Menu>
		</React.Fragment>
	);
}
