import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import { db } from "../firebase";
import { EOIImage } from "../assets";
import withSizes from "react-sizes";
import validator from "validator";

function EOIModal(props) {
	const [open, setOpen] = React.useState(false);
	const [error, setError] = React.useState({
		name: false,
		email: false,
	});
	const [values, setValues] = React.useState({
		name: "",
		email: "",
	});
	const [flag, setFlag] = React.useState(false);

	const handleChange = (name) => (event) => {
		setValues({ ...values, [name]: event.target.value });
		setError({ ...error, name: false, email: false });
	};

	useEffect(() => {
		setOpen(props.open);
		var str = props.btn ? props.btn : "lorem";
		var res = str.split("-");
		if (res[0] === "f") setFlag(true);
		else setFlag(false);
	}, [props.open]);

	useEffect(() => {
		if (props.signedIn) {
			db.getMeta().then((res) => {
				let name = "";
				let email = "";
				if (res.firstName) name = res.firstName;
				if (res.lastName) name = name + " " + res.lastName;
				if (res.email) email = res.email;
				setValues({ name: name, email: email });
			});
		}
	}, [props.signedIn]);

	const handleClose = () => {
		props.closeModal();
		setOpen(false);
	};

	const handleSubmit = () => {
		if (values.name === "") {
			setError({ ...error, name: true });
		} else if (!validator.isEmail(values.email) || values.email === "") {
			setError({ ...error, email: true });
		} else {
			let location = window.location.href;
			db.submitInterest(values.name, values.email, moment().format(), location, props.btn);
			props.closeModal();
			setOpen(false);
		}
	};
	const buyStyle = {
		marginTop: 16,
		backgroundColor: "#58c8f5",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#0eb1f1",
			boxShadow: "none",
		},
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} style={{ width: props.isMobile ? null : 500, margin: "auto" }}>
				<img src={EOIImage} alt='Expression of Interest - Marketplace' style={{ height: 90, width: 90, margin: "auto", marginTop: 24 }} />
				<h2 style={{ fontWeight: 700, fontSize: 20, padding: "0px 24px" }}>
					{!flag ? "Submit offer" : props.signedIn ? null : "Request to join marketplace"}
				</h2>
				<p style={{ fontWeight: 600, fontSize: 16, padding: "0px 24px", marginTop: 0 }}>
					{!flag
						? "Enter your name and email address to make an offer, and a team member will reach out once there is a buyer/seller found."
						: "If you would like to add to our marketplace, please add your name and email, and a team member will contact you shortly."}
				</p>
				<br />
				<div style={{ padding: "0px 24px", marginBottom: 48 }}>
					<TextField
						autoFocus={props.signedIn ? false : true}
						error={error.name}
						margin='dense'
						variant='outlined'
						id='name'
						label='Name'
						fullWidth
						value={values.name}
						onChange={handleChange("name")}
					/>
					<TextField
						error={error.email}
						margin='dense'
						variant='outlined'
						id='email'
						label='Email'
						type='email'
						fullWidth
						value={values.email}
						onChange={handleChange("email")}
					/>
					<Button variant='contained' style={buyStyle} fullWidth onClick={handleSubmit}>
						{!flag ? "Submit Offer" : props.signedIn ? "Submit" : "Request to Join"}
					</Button>
				</div>
			</Dialog>
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(EOIModal);
