import React, { useState, useEffect } from "react";
import withSizes from "react-sizes";
import { Footer, Header, SignInModal } from "../components";
import { db } from "../firebase";

function PageContainer(props) {
	const { isMobile, mobHeader } = props;
	const [search, setSearch] = useState("");
	const [initials, setInitials] = useState("");
	const [signInOpen, setSignInOpen] = useState(false);
	const handleSearch = (s) => {
		setSearch(s);
	};
	useEffect(() => {
		if (props.signedIn) {
			db.initials().then((res) => {
				setInitials(res);
			});
		}
	}, [props.signedIn]);

	return (
		<div>
			<Header mobHeader={mobHeader} search={handleSearch} signedIn={props.signedIn} initials={initials} signInModal={() => setSignInOpen(!signInOpen)} />
			{React.cloneElement(props.page(), { searchText: search, signInModal: () => setSignInOpen(!signInOpen), signedIn: props.signedIn }, null)}
			<Footer isMobile={isMobile} />
			<SignInModal open={signInOpen} closeModal={() => setSignInOpen(false)} />
		</div>
	);
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	mobHeader: width < 1000,
});

export default withSizes(mapSizesToProps)(PageContainer);
