import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
	root: {
		padding: 150,
		minHeight: "75vh",
		flexGrow: 1,
		fontFamily: theme.palette.fontFamily
	},
	main: {
		color: theme.palette.primary.main,
		fontWeight: 900
	},
	secondary: {
		fontWeight: 900,
		color: theme.palette.secondary.dark
	}
});
function PageNotFound(props) {
	const { classes } = props;
	return (
		<div className={classes.root}>
			<h1>
				<span className={classes.main}>404</span>
				<span className={classes.secondary}> Page Not Found!</span>
			</h1>
		</div>
	);
}

export default withStyles(styles)(PageNotFound);
