import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { EOIModal } from "../components";
import withSizes from "react-sizes";
import _ from "lodash";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ProductCard, Subheading } from "../components";
import { fb } from "../firebase";

const useStyles = makeStyles((theme) => ({
	heroContent: {
		backgroundImage: `url("https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/EnterOfferMarkeplaceBanner.png")`,
		textAlign: "left",
		backgroundPosition: "center top",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
	banner: {
		maxWidth: "1440px",
	},
	cardGrid: {
		margin: "auto",
		marginTop: theme.spacing(4),
		maxWidth: "1440px",
		textAlign: "left",
	},
	choiceHeader: {
		backgroundColor: "#f6f6f8",
		textAlign: "center",
	},
	choiceHeaderMob: {
		textAlign: "center",
		padding: "24px !important",
		borderBottom: "1px solid #e3e3e3",
	},
	choice: {
		backgroundColor: "#fff",
		textAlign: "center",
	},
	choiceMob: {
		backgroundColor: "#fff",
		textAlign: "center",
		padding: theme.spacing(2),
	},
	buy: {
		backgroundColor: "#58c8f5",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		// height: "100%",
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#0eb1f1",
			boxShadow: "none",
		},
	},
	sell: {
		backgroundColor: "#71db80",
		color: "#fff",
		boxShadow: "none",
		// height: "100%",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#47d15a",
			boxShadow: "none",
		},
	},
	greyText: {
		color: "#929292",
	},
	inputHowTo: {
		padding: "6px 0 7px",
		fontWeight: "700",
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	);
}
function shuffle(array) {
	array.sort(() => Math.random() - 0.5);
	return array;
}

// function shuffle(array, seed) {
// 	let currentIndex = array.length,
// 		temporaryValue,
// 		randomIndex;
// 	seed = seed || 1;
// 	let random = function() {
// 		var x = Math.sin(seed++) * 10000;
// 		return x - Math.floor(x);
// 	};
// 	// While there remain elements to shuffle...
// 	while (0 !== currentIndex) {
// 		// Pick a remaining element...
// 		randomIndex = Math.floor(random() * currentIndex);
// 		currentIndex -= 1;
// 		// And swap it with the current element.
// 		temporaryValue = array[currentIndex];
// 		array[currentIndex] = array[randomIndex];
// 		array[randomIndex] = temporaryValue;
// 	}
// 	return array;
// }

function AllPage(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [btn, setBtn] = useState();
	const products = props.products;
	const [tab, setTab] = React.useState(0);
	const [search, setSearch] = React.useState("");
	const [filteredCards, setFilteredCards] = useState([]);
	const [following, setFollowing] = useState([]);

	useEffect(() => {
		fb.getAllFollowing()
			.then((res) => {
				setFollowing(res.data);
			})
			.catch(console.error);
	}, [props.signedIn]);

	useEffect(() => {
		setFilteredCards(shuffle(products));
	}, [products]);

	useEffect(() => {
		setSearch(props.searchText);
		if (props.searchText !== "") filter(props.searchText);
		else setFilteredCards(shuffle(products));
	}, [props.searchText]);

	const handleChangeTab = (event, newtab) => {
		setTab(newtab);
	};

	const handleChange = (event) => {
		setSearch(event.target.value);
		filter(event.target.value);
	};

	const filter = (str) => {
		var searchPattern = new RegExp(_.lowerCase(str));

		var newCards = _.filter(products, function (obj) {
			return searchPattern.test(_.lowerCase(obj["productName"])) || searchPattern.test(_.lowerCase(obj["category"]));
		});
		setFilteredCards(newCards);
	};

	const handleOpenEOI = (id) => {
		setBtn(id);
		setOpen(true);
	};

	const handleCloseEOI = () => {
		setBtn("");
		setOpen(false);
	};

	return (
		<main>
			<Fade>
				<div
					className={classes.heroContent}
					style={{
						height: props.isMobile ? null : 600,
						padding: props.isMobile ? "64px 0px 112px" : "112px 0px 48px",
					}}
				>
					<Container maxWidth={false} className={classes.banner}>
						<Grid container spacing={2} alignItems="stretch" style={{ height: props.isMobile ? null : 500 }}>
							<Grid item xs={6} md={5}>
								<h1
									style={{
										fontSize: props.isMobile ? "20px" : "48px",
										lineHeight: 1.4,
										color: "#fff",
										fontWeight: 700,
									}}
								>
									A different kind of marketplace.
								</h1>
								<Subheading isMobile={props.isMobile} />
							</Grid>
							{props.isMobile ? null : (
								<Grid container item xs={12} spacing={2} style={{ height: props.isMobile ? null : 100 }}>
									<Grid item xs={12} md={12} className={classes.choiceHeader}>
										Add something you're looking to...
									</Grid>
									<Grid item xs={5} md={5} className={classes.choice}>
										<Button variant="contained" className={classes.buy} onClick={() => handleOpenEOI(`f-buyChoice`)}>
											Buy
										</Button>
									</Grid>
									<Grid item xs={2} md={2} className={classes.choice}>
										<Divider orientation="vertical" style={{ margin: "auto" }} />
									</Grid>

									<Grid item xs={5} md={5} className={classes.choice}>
										<Button variant="contained" className={classes.sell} onClick={() => handleOpenEOI(`f-sellChoice`)}>
											Sell
										</Button>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Container>
				</div>
			</Fade>
			<Paper className={classes.choiceHeader} elevation={0} style={{ borderBottom: "1px solid #e3e3e3", borderRadius: 0 }}>
				<Tabs
					value={tab}
					onChange={handleChangeTab}
					textColor="inherit"
					centered
					style={{ zoom: props.isMobile ? 0.8 : null }}
					TabIndicatorProps={{
						style: {
							height: "0px",
						},
					}}
				>
					<Tab label="Marketplace" style={{ marginRight: props.isMobile ? 10 : 50 }} />
					<Tab label="How it Works" style={{ marginLeft: props.isMobile ? 10 : 50 }} />
				</Tabs>
			</Paper>
			{props.isMobile && tab !== 1 ? (
				<Grid container spacing={0} justify="center" alignItems="stretch" style={{ borderBottom: "1px solid #e3e3e3" }}>
					<Grid item xs={12} md={12} className={classes.choiceHeaderMob}>
						Add something you're looking to...
					</Grid>
					<Grid item xs={5} md={5} className={classes.choiceMob}>
						<Button variant="contained" fullWidth className={classes.buy} onClick={() => handleOpenEOI(`f-buyChoice`)}>
							Buy
						</Button>
					</Grid>
					<Grid item xs={2} md={2} className="mobChoiceDivider" style={{ padding: "12px 0px" }}>
						<Divider orientation="vertical" style={{ margin: "auto" }} />
					</Grid>

					<Grid item xs={5} md={5} className={classes.choiceMob}>
						<Button variant="contained" fullWidth className={classes.sell} onClick={() => handleOpenEOI("f-sellChoice")}>
							Sell
						</Button>
					</Grid>
				</Grid>
			) : null}
			{props.isMobile && tab !== 1 ? <div style={{ backgroundColor: "#f6f6f8", border: "1px solid #e3e3e3", height: "2em" }}></div> : null}
			<TabPanel value={tab} index={0}>
				<Fade>
					<Container className={classes.cardGrid} style={{ marginTop: props.isMobile ? 16 : 32 }} maxWidth={false} id="offers">
						<Grid container spacing={props.isMobile ? 1 : 2}>
							{props.mobHeader ? (
								<Grid item xs={12}>
									<TextField
										className={classes.textField}
										value={search}
										onChange={handleChange}
										InputProps={{
											disableUnderline: true,
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
							) : null}
							{/*
							<Grid item xs={4}>
								 <IconButton aria-label='settings' style={{ float: "right", padding: props.isMobile ? 0 : 4 }}>
									<TuneIcon />
								</IconButton> 
							</Grid>
							 */}
							{filteredCards.map((card) => (
								<Grid item container key={card.id.toLowerCase()} xs={6} sm={4} md={3} style={{ textAlign: "center", position: "relative" }}>
									<ProductCard
										card={card}
										eoi={handleOpenEOI}
										followers={following && following[1] && following[1][card.id] ? following[1][card.id] : 0}
										following={following && following[0] && following[0][card.id] ? true : false}
										signInModal={props.signInModal}
										signedIn={props.signedIn}
									/>
								</Grid>
							))}
						</Grid>
					</Container>
				</Fade>
			</TabPanel>
			<TabPanel value={tab} index={1}>
				<Fade>
					<Grid container item spacing={props.isMobile ? 4 : 10} justify="center" xs={12} sm={9} md={7} style={{ margin: "auto" }}>
						<Grid item xs={12} style={{ paddingBottom: 0 }}>
							<LazyLoadImage
								effect="blur"
								src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/howToMarketplace.jpeg"
								alt="How to use EnterOffer Marketplace"
								height={300}
								style={{ objectFit: props.isMobile ? "cover " : "contain", width: "100%" }}
							/>
						</Grid>
						<Grid item xs={6}>
							<Button variant="contained" className={classes.buy} fullWidth onClick={() => handleOpenEOI(`f-buyHowTo`)}>
								Buy $50
							</Button>
							<p>
								This product is available to buy now for $50.{" "}
								<span className={classes.greyText}>This is the cheapest sellers are prepared to sell for.</span>
							</p>
						</Grid>
						<Grid item xs={6}>
							<Button variant="contained" className={classes.sell} fullWidth onClick={() => handleOpenEOI(`f-sellHowTo`)}>
								Sell $45
							</Button>
							<p>
								If you own this product you can sell it now for $45.{" "}
								<span className={classes.greyText}>This is the maximum buyers are willing to pay.</span>
							</p>
						</Grid>
						<Grid item xs={6} style={{ display: "inline", textAlign: "center" }}>
							<TextField
								variant="outlined"
								id="offer-howToBuy"
								style={{
									marginRight: "3%",
									width: props.isMobile ? null : "37%",
									marginBottom: props.isMobile ? 8 : null,
								}}
								fullWidth={props.isMobile ? true : null}
								InputProps={{
									type: "number",
									pattern: "[0-9]*",
									inputMode: "numeric",
									step: "1",
									classes: {
										input: classes.inputHowTo,
									},
									startAdornment: (
										<InputAdornment position="start">
											<span
												style={{
													color: "#929292",
													fontWeight: "700",
												}}
											>
												$
											</span>
										</InputAdornment>
									),
								}}
							/>
							<Button
								variant="contained"
								className={classes.buy}
								style={{ width: props.isMobile ? null : "60%" }}
								onClick={() => handleOpenEOI(`f-buyHowTo`)}
							>
								Submit a buy offer
							</Button>
							<p>
								Is $50 too expensive for you to buy at? <span className={classes.greyText}>Submit a lower buy offer.</span>
							</p>
						</Grid>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								id="offer-howToSell"
								style={{
									marginRight: "3%",
									width: props.isMobile ? null : "37%",
									marginBottom: props.isMobile ? 8 : null,
								}}
								InputProps={{
									type: "number",
									pattern: "[0-9]*",
									inputMode: "numeric",
									step: "1",
									classes: {
										input: classes.inputHowTo,
									},
									startAdornment: (
										<InputAdornment position="start">
											<span
												style={{
													color: "#929292",
													fontWeight: "700",
												}}
											>
												$
											</span>
										</InputAdornment>
									),
								}}
							/>
							<Button
								variant="contained"
								className={classes.sell}
								style={{ width: props.isMobile ? null : "60%" }}
								onClick={() => handleOpenEOI(`f-sellHowTo`)}
							>
								Submit a sell offer
							</Button>
							<p>
								Is $45 too cheap for you to sell at? <span className={classes.greyText}>Submit a higher sell offer.</span>
							</p>
						</Grid>
					</Grid>
					<Container className={classes.cardGrid} maxWidth={false} id="marketplaceInformation">
						<Typography gutterBottom variant="body2" component="h2">
							<b>Buy offers</b>
							<br /> EnterOffer puts a card payment hold on all buy offers. Funds are released to the seller who matches the buy offer.
						</Typography>
						<br />
						<Typography gutterBottom variant="body2" component="h2">
							<b>Sell offers</b>
							<br /> List items for sale on our marketplace. Sell for the advertised price or nearest offer.
						</Typography>
						<br />
						<Typography gutterBottom variant="body2" component="h2">
							<b>Ownership</b>
							<br /> EnterOffer does not directly own or sell any of the products or images advertised. They are added by our community of buyers
							and sellers.
						</Typography>
					</Container>
					<br />
					<br />
					<br />
					<br />
					<Grid container style={{ padding: 16 }}>
						<Grid item xs={12} md={6}>
							<LazyLoadImage
								effect="blur"
								alt="sponsor-logo"
								src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/NSWGovernment.png"
								style={{ marginBottom: 0, objectFit: "cover", height: "150px" }}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<LazyLoadImage
								effect="blur"
								alt="sponsor-logo"
								src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/H2Ventures.png"
								style={{ marginBottom: 0, objectFit: "contain", height: "150px", maxWidth: "100%" }}
							/>
						</Grid>
					</Grid>
				</Fade>
			</TabPanel>
			<EOIModal open={open} closeModal={handleCloseEOI} btn={btn} signedIn={props.signedIn} />
			<br />
			<br />
		</main>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	mobHeader: width < 1000,
});

export default withSizes(mapSizesToProps)(withRouter(AllPage));
