import { userfb } from "./";
export function updateFollowing(id, del) {
	const f = userfb.functions().httpsCallable("marketUpdateFollowing");
	return new Promise((resolve, reject) => {
		return f({ id, delete: del })
			.then((res) => {
				return resolve(res);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function getAllFollowing() {
	const f = userfb.functions().httpsCallable("marketGetAllFollowing");
	return new Promise((resolve, reject) => {
		return f()
			.then((res) => {
				return resolve(res);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function getFollowing(id) {
	const f = userfb.functions().httpsCallable("marketGetFollowing");
	return new Promise((resolve, reject) => {
		return f({ id })
			.then((res) => {
				return resolve(res);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}
