import ReactPixel from "react-facebook-pixel";
if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
	ReactPixel.init(
		"2037471866271074",
		{},
		{
			autoConfig: true,
			debug: false,
		}
	);
}

export default ReactPixel;
