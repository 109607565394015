import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Button, List, ListItem, ListItemText, Link } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { EOIModal } from "../components";

const useStyles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: "auto",
	},
	navLinks: {
		textTransform: "capitalize",
		padding: 0,
		margin: 0,
		minWidth: 32,
	},
});

export default function TemporaryDrawer(props) {
	const classes = useStyles();
	const [state, setState] = React.useState(false);

	const [open, setOpen] = useState(false);
	const [btn, setBtn] = useState();

	const handleOpenEOI = (id) => {
		setBtn(id);
		setOpen(true);
	};

	const handleCloseEOI = () => {
		setBtn("");
		setOpen(false);
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setState(open);
	};

	const list = (anchor) => (
		<div className={clsx(classes.list)} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
			<List>
				{/* <Link color='inherit' href='/' style={{ textDecoration: "none" }}> */}
				<ListItem button onClick={() => handleOpenEOI(`f-navFollow`)}>
					<ListItemText primary={"Following"} />
				</ListItem>
				{/* </Link> */}
				{/* <Link color='inherit' href='/orders' style={{ textDecoration: "none" }}> */}
				<ListItem button onClick={() => handleOpenEOI(`f-navOrders`)}>
					<ListItemText primary={"Orders"} />
				</ListItem>
				{/* </Link> */}
				{/* <Link color='inherit' href='/orders' style={{ textDecoration: "none" }}> */}
				<ListItem button onClick={() => handleOpenEOI(`f-navBuy`)}>
					<ListItemText primary={"Buy"} />
				</ListItem>
				{/* </Link> */}
				{/* <Link color='inherit' href='/orders' style={{ textDecoration: "none" }}> */}
				<ListItem button onClick={() => handleOpenEOI(`f-navSell`)}>
					<ListItemText primary={"Sell"} />
				</ListItem>
				{/* </Link> */}
				{/* <Link color='inherit' href='/orders' style={{ textDecoration: "none" }}> */}
				{/*<ListItem button onClick={() => handleOpenEOI(`f-navNotif`)}>
					<ListItemText primary={"Notifications"} />
				</ListItem>*/}
				{/* </Link> */}
				<Link color="inherit" href="/signin" style={{ textDecoration: "none" }}>
					<ListItem button>
						<Button variant="contained" color="primary" disableElevation>
							<ListItemText primary={"SIGN IN"} />
						</Button>
					</ListItem>
				</Link>
			</List>
		</div>
	);

	return (
		<div>
			<Button className={classes.navLinks} onClick={toggleDrawer("left", true)} style={{ color: props.clr }}>
				<MenuIcon style={{ padding: 0 }} />
			</Button>
			<Drawer anchor={"left"} open={state} onClose={toggleDrawer("left", false)}>
				{list("left")}
			</Drawer>
			<EOIModal open={open} closeModal={handleCloseEOI} btn={btn} />
		</div>
	);
}
