import React from "react";
import { Route, Redirect } from "react-router-dom";

function AuthorizedRoute(props) {
	const { component: Component, signedIn, authPending, ...rest } = props;
	console.log(authPending, signedIn);
	return (
		<Route
			{...rest}
			render={(renderProps) => {
				return authPending ? null : signedIn ? (
					<Component {...renderProps} signedIn={signedIn} />
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: renderProps.location },
						}}
					/>
				);
			}}
		/>
	);
}

export default AuthorizedRoute;
