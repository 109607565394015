import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import { EOIModal } from "../components";
import withSizes from "react-sizes";
import _ from "lodash";
import Fade from "react-reveal/Fade";
import { fb } from "../firebase";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { ProductCard } from "../components";

const useStyles = makeStyles((theme) => ({
	cardGrid: {
		margin: "auto",
		maxWidth: "1440px",
		textAlign: "left",
	},
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		border: "1px solid #f6f6f8",
		backgroundColor: "#f9f9f9",
	},
	cardMedia: {
		// paddingTop: "56.25%",
		marginBottom: 0,
		objectFit: "contain",
		maxHeight: 280,
		// minHeight: 300,
		filter: "brightness(0.98)",
	},
	buy: {
		backgroundColor: "#58c8f5",
		color: "#fff",
		boxShadow: "none",
		height: "100%",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#0eb1f1",
			boxShadow: "none",
		},
	},
	sell: {
		backgroundColor: "#71db80",
		color: "#fff",
		boxShadow: "none",
		height: "100%",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#47d15a",
			boxShadow: "none",
		},
	},
	greyText: {
		color: "#929292",
	},
	follow: {
		backgroundColor: "#3e3e3e",
		color: "#fff",
		borderRadius: 20,
		boxShadow: "0 3px 4px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.23)",
		zIndex: 99,
		position: "absolute",
		right: 15,
		top: 15,
		fontSize: 14,
		fontWeight: 600,
		cursor: "pointer",
		padding: "4px 20px",
		textAlign: "center",
		margin: 0,
		marginBottom: theme.spacing(3),
		justifyContent: "center",
		alignItems: "center",
		"&:hover": {
			// backgroundColor: "#3e3e3e11",
			boxShadow: "0 3px 8px rgba(0,0,0,0.16), 0 3px 8px rgba(0,0,0,0.23)",
		},
	},
}));

function followList(array, list) {
	let fList = Object.keys(list);
	var arr = _.filter(array, (obj) => {
		return fList.indexOf(obj["id"]) !== -1;
	});
	return arr;
}

function Following(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [btn, setBtn] = useState();
	const products = props.products;
	const [search, setSearch] = React.useState("");
	const [filteredCards, setFilteredCards] = useState([]);
	const [loading, setLoading] = useState(true);
	const [following, setFollowing] = useState([]);
	const [empty, setEmpty] = useState(false);

	useEffect(() => {
		fb.getAllFollowing()
			.then((res) => {
				if (res.data[0] === null) {
					setEmpty(true);
				} else {
					setFollowing(res.data[1]);
					setFilteredCards(followList(props.products, res.data[0]));
				}
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
			});
	}, [props.products]);

	useEffect(() => {
		setSearch(props.searchText);
		if (props.searchText !== "") filter(props.searchText);
	}, [props.searchText]);

	const handleChange = (event) => {
		setSearch(event.target.value);
		filter(event.target.value);
	};

	const filter = (str) => {
		// console.log(str);
		var searchPattern = new RegExp(_.lowerCase(str));

		var newCards = _.filter(products, function (obj) {
			return searchPattern.test(_.lowerCase(obj["productName"]));
		});
		setFilteredCards(newCards);
	};

	const handleOpenEOI = (id, o) => {
		setBtn(id);
		setOpen(o);
	};

	const handleCloseEOI = () => {
		setBtn("");
		setOpen(false);
	};

	return (
		<Fade>
			{loading ? (
				<div
					style={{
						left: "50%",
						top: "50%",
						marginLeft: "auto",
						marginRight: "auto",
						display: "inline-block",
						height: "100vh",
					}}
				>
					<Lottie
						height={300}
						width={300}
						options={{
							loop: true,
							autoplay: true,
							animationData: loader.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice",
							},
						}}
						style={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%) translateX(-50%)",
							left: "50%",
						}}
					/>
				</div>
			) : empty ? (
				<div
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						display: "inline-block",
						height: "100vh",
					}}
				>
					<Typography
						style={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%) translateX(-50%)",
							left: "50%",
						}}
					>
						You are not following any products :(
					</Typography>
				</div>
			) : (
				<Container className={classes.cardGrid} style={{ marginTop: props.isMobile ? 32 : 72 }} maxWidth={false} id="offers">
					<br />
					<h1>Following</h1>
					<Grid container spacing={props.isMobile ? 1 : 2}>
						{props.isMobile ? (
							<Grid item xs={8}>
								<TextField
									className={classes.textField}
									value={search}
									onChange={handleChange}
									InputProps={{
										disableUnderline: true,
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						) : null}
						{/*
							<Grid item xs={4}>
								 <IconButton aria-label='settings' style={{ float: "right", padding: props.isMobile ? 0 : 4 }}>
									<TuneIcon />
								</IconButton> 
							</Grid>
                             */}
						{filteredCards.map((card) => (
							<Grid item container key={card.id.toLowerCase()} xs={6} sm={4} md={3} style={{ textAlign: "center", position: "relative" }}>
								<ProductCard
									card={card}
									eoi={handleOpenEOI}
									following={true}
									followers={following && following[card.id] ? following[card.id] : 0}
									signedIn={props.signedIn}
								/>
							</Grid>
						))}
						<EOIModal open={open} closeModal={handleCloseEOI} btn={btn} />
					</Grid>
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
				</Container>
			)}
		</Fade>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(Following);
