import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { EOIModal } from "../components";
import withSizes from "react-sizes";
import _ from "lodash";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const useStyles = makeStyles((theme) => ({
	cardGrid: {
		margin: "auto",
		maxWidth: "1440px",
		textAlign: "left",
	},
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		border: "1px solid #f6f6f8",
		backgroundColor: "#f9f9f9",
	},
	cardMedia: {
		// paddingTop: "56.25%",
		marginBottom: 0,
		objectFit: "contain",
		maxHeight: 280,
		// minHeight: 300,
		filter: "brightness(0.98)",
	},
	buy: {
		backgroundColor: "#58c8f5",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#0eb1f1",
			boxShadow: "none",
		},
	},
	sell: {
		backgroundColor: "#71db80",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#47d15a",
			boxShadow: "none",
		},
	},
	greyText: {
		color: "#929292",
	},
	follow: {
		backgroundColor: "#3e3e3e",
		color: "#fff",
		borderRadius: 20,
		boxShadow: "0 3px 4px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.23)",
		zIndex: 99,
		position: "absolute",
		right: 15,
		top: 15,
		fontSize: 14,
		fontWeight: 600,
		cursor: "pointer",
		padding: "4px 20px",
		"&:hover": {
			// backgroundColor: "#3e3e3e11",
			boxShadow: "0 3px 8px rgba(0,0,0,0.16), 0 3px 8px rgba(0,0,0,0.23)",
		},
	},
}));

function shuffle(array) {
	array.sort(() => Math.random() - 0.5);
	var arr = array.slice(0, 10); // subset
	return arr;
}

function Orders(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [btn, setBtn] = useState();
	const products = props.products;
	const [search, setSearch] = React.useState("");
	const [filteredCards, setFilteredCards] = useState([]);

	useEffect(() => {
		setFilteredCards(shuffle(products));
	}, [products]);

	useEffect(() => {
		setSearch(props.searchText);
		if (props.searchText !== "") filter(props.searchText);
	}, [props.searchText]);

	const handleChange = (event) => {
		setSearch(event.target.value);
		filter(event.target.value);
	};

	const filter = (str) => {
		// console.log(str);
		var searchPattern = new RegExp(_.lowerCase(str));

		var newCards = _.filter(products, function (obj) {
			return searchPattern.test(_.lowerCase(obj["productName"]));
		});
		setFilteredCards(newCards);
	};

	const handleOpenEOI = (id) => {
		setBtn(id);
		setOpen(true);
	};

	const handleCloseEOI = () => {
		setBtn("");
		setOpen(false);
	};
	const randomNumber = (min, max) => {
		return Math.floor(Math.random() * (max - min) + min);
	};

	return (
		<Fade>
			<Container className={classes.cardGrid} style={{ marginTop: props.isMobile ? 32 : 78 }} maxWidth={false} id="offers">
				<Grid container spacing={props.isMobile ? 1 : 2}>
					{props.isMobile ? (
						<Grid item xs={8}>
							<TextField
								className={classes.textField}
								value={search}
								onChange={handleChange}
								InputProps={{
									disableUnderline: true,
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					) : null}
					{/*
							<Grid item xs={4}>
								 <IconButton aria-label='settings' style={{ float: "right", padding: props.isMobile ? 0 : 4 }}>
									<TuneIcon />
								</IconButton> 
							</Grid>
                             */}
					{filteredCards.map((card) => (
						<Grid item container key={card.id.toLowerCase()} xs={12} spacing={4} style={{ textAlign: "center", position: "relative" }}>
							<Grid item xs={12} md={4}>
								<Link
									to={{ pathname: `/${card.id.toLowerCase()}` }}
									style={{
										textDecoration: "none",
										width: "100%",
									}}
								>
									<Card className={classes.card} id="prodContainer" elevation={0}>
										<LazyLoadImage
											effect="blur"
											id={card.id.toLowerCase()}
											className={classes.cardMedia}
											style={{ minHeight: props.isMobile ? 200 : 300, width: "100%" }}
											src={card.img}
											alt={card.productName}
										/>
									</Card>
								</Link>
							</Grid>

							<Grid
								item
								xs={12}
								md={4}
								style={{
									textAlign: "left",
									fontWeight: 700,
									padding: "16px 8px",
									height: props.isMobile ? "3rem" : "4rem",
								}}
							>
								<p className="wrapText" style={{ fontSize: props.isMobile ? "0.7rem" : null }}>
									{card.productName}
								</p>
							</Grid>
							<Grid item xs={12} md={4} style={{ display: "inline", marginBottom: 40, fontWeight: 600 }}>
								Bought For <br />
								<b>{card.highestOffer}</b>
								<br />
								<br />
								Savings <br />
								<b>${randomNumber(5, 45)}</b>
								<br />
							</Grid>
						</Grid>
					))}
					<EOIModal open={open} closeModal={handleCloseEOI} btn={btn} />
				</Grid>
			</Container>
		</Fade>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(withRouter(Orders));
