import * as images from "../assets";

const products = [
	{
		productName: "PowerTrain Adjustable 48kg Dumbbell Pair",
		highestOffer: "$300",
		lowestAsk: "$549",
		id: "powertrain-adjustable-dumbbell-pair-48kg",
		img: images.PowertrainAdjustableDumbbellPair48Kg,
		metaDesc:
			"The PowerTrain Adjustable 48kg Dumbbell Pair is a practical and customisable fitness equipment for building your muscles and toning your body. Easy to use, a simple turn of a dial is all you need to get the resistance level you require for each exercise.",
		keywords: "PowerTrain Adjustable Dumbbell Pair 48kg",
		desc:
			"The PowerTrain Adjustable 48kg Dumbbell Pair is a practical and customisable fitness equipment for building your muscles and toning your body. Easy to use, a simple turn of a dial is all you need to get the resistance level you require for each exercise.",
		sellOffers: [
			{ offer: 450, condition: "New", location: "Sydney CBD" },
			{ offer: 370, condition: "New", location: "Australia" },
			{ offer: 365, condition: "New", location: "Australia" },
			{ offer: 225, condition: "Used", location: "Surry Hills" },
			{ offer: 190, condition: "Used", location: "Rhodes" },
			{ offer: 180, condition: "Used", location: "Rhodes" },
			{ offer: 170, condition: "Used", location: "Rhodes" },
		],
		buyOffers: [
			{ offer: 549, condition: "New", location: "Australia" },
			{ offer: 569, condition: "New", location: "Australia" },
		],
		followers: 10,
		category: "Fitness",
	},
	{
		productName: "Supreme Waist Bag (FW18) Black",
		highestOffer: "$110",
		lowestAsk: "$120",
		id: "supreme-waist-bag-fw18-black",
		img: images.SupremeWaistBagFw18Black,
		metaDesc:
			"Buy and sell authentic Supreme streetwear on EnterOFfer including the Supreme Waist Bag (FW18) Black and thousands of other streetwear clothing and accessories.",
		keywords: "supreme waist bag (fw18) black, supreme streetwear fw18",
		desc: "",
		sellOffers: [
			{ offer: 110, condition: "New", location: "Vaucluse" },
			{ offer: 100, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 120, condition: "New", location: "Australia" }],
		followers: 12,
		category: "Accessories",
	},
	{
		productName: "Supreme Backpack (SS19) Black",
		highestOffer: "$180",
		lowestAsk: "$200",
		id: "supreme-backpack-ss19-black",
		img: images.SupremeBackpackSs19Black,
		metaDesc:
			"Buy and sell authentic Supreme streetwear on EnterOffer including the Supreme Backpack (SS19) Black and thousands of other streetwear clothing and accessories.",
		keywords: "supreme backpack (ss19) black, supreme streetwear ss19",
		desc: "",
		sellOffers: [
			{ offer: 180, condition: "New", location: "Australia" },
			{ offer: 155, condition: "New", location: "Wentworth Point" },
		],
		buyOffers: [{ offer: 200, condition: "New", location: "Australia" }],
		followers: 20,
		category: "Accessories",
	},
	{
		productName: "FTP All Over Hoodie Black",
		highestOffer: "$350",
		lowestAsk: "$370",
		id: "ftp-all-over-hoodie-black",
		img: images.FtpAllOverHoodieBlack,
		metaDesc:
			"Buy and sell authentic FTP streetwear on EnterOffer including the FTP All Over Hoodie Black and thousands of other streetwear clothing and accessories.",
		keywords: "other brands, ftp, streetwear, apparel, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 320, condition: "New", location: "Parramatta" },
			{ offer: 160, condition: "Used", location: "Granville" },
		],
		buyOffers: [{ offer: 370, condition: "New", location: "Australia" }],
		followers: 5,
		category: "Clothing",
	},
	{
		productName: "Chanel Boy Bag",
		highestOffer: "$6,500",
		lowestAsk: "$7,010",
		id: "chanel-boy-bag",
		img: images.ChanelBoyBag,
		metaDesc: "Discover the CHANEL Boy Bag with EnterOffer Marketplace",
		keywords: "chanel, boy, bag, apparel, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 6500, condition: "New", location: "Epping" },
			{ offer: 4750, condition: "New", location: "Redfern" },
			{ offer: 3600, condition: "New", location: "Lindfield" },
		],
		buyOffers: [{ offer: 7010, condition: "New", location: "Australia" }],
		followers: 14,
		category: "Accessories",
	},
	{
		productName: "Valentino Garavani Rockstud Medium Textured-Leather Tote",
		highestOffer: "$2,500",
		lowestAsk: "$3,340",
		id: "valentino-garavani-rockstud-medium-textured-leather-tote",
		img: images.ValentinoGaravaniRockstudMediumTexturedLeatherTote,
		metaDesc: "Discover the  Valentino Garavani Medium Rockstud bag with EnterOffer Marketplace",
		keywords: "Valentino, Rockstud, bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2500, condition: "New", location: "Liverpool" },
			{ offer: 1600, condition: "New", location: "Hurstville" },
			{ offer: 1350, condition: "New", location: "Australia" },
			{ offer: 999, condition: "New", location: "Sydney CBD" },
		],
		buyOffers: [{ offer: 3340, condition: "New", location: "Australia" }],
		followers: 4,
		category: "Accessories",
	},
	{
		productName: "Gucci Dionysus Medium GG Shoulder Bag",
		highestOffer: "$2,850",
		lowestAsk: "$3,170",
		id: "Gucci-Dionysus-Medium-GG-Shoulder-Bag",
		img: images.GucciDionysusMediumGGShoulderBag,
		metaDesc: "Discover the  Gucci Dionysus Shoulder bag with EnterOffer Marketplace",
		keywords: "Gucci, Dionysus, shoulder, bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2850, condition: "New", location: "Sydney CBD" },
			{ offer: 1800, condition: "New", location: "North Rocks" },
		],
		buyOffers: [{ offer: 3170, condition: "New", location: "Australia" }],
		followers: 23,
		category: "Accessories",
	},
	{
		productName: "Gucci GG Marmont Matelassé Shoulder Bag",
		highestOffer: "$1,450",
		lowestAsk: "$1,685",
		id: "Gucci-Marmon-Metalassé-Shoulder-Bag",
		img: images.GucciGGMarmontMatelasséShoulderBag,
		metaDesc: "Discover the  Gucci Marmont Matelassé Shoulder bag with EnterOffer Marketplace",
		keywords: "Gucci, Marmont, Matelassé, shoulder, bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 1450, condition: "New", location: "Sydney CBD" },
			{ offer: 1200, condition: "New", location: "North Rocks" },
		],
		buyOffers: [{ offer: 1685, condition: "New", location: "Australia" }],
		followers: 27,
		category: "Accessories",
	},
	{
		productName: "Louis Vuitton Neverfull GM",
		highestOffer: "$2,150",
		lowestAsk: "$2,300",
		id: "Louis-Vuitton-Neverfull-GM",
		img: images.LouisVuittonNeverfullGM,
		metaDesc: "Discover the Louis Vuitton Neverfull GM bag with EnterOffer Marketplace",
		keywords: "Louis, Vuitton, Neverfull, shoulder, bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2150, condition: "New", location: "Sydney CBD" },
			{ offer: 1800, condition: "New", location: "North Rocks" },
		],
		buyOffers: [{ offer: 2300, condition: "New", location: "Australia" }],
		followers: 30,
		category: "Accessories",
	},
	{
		productName: "Loewe Woven Textured-Leather Tote",
		highestOffer: "$4,800",
		lowestAsk: "$5,100",
		id: "Loewe-Woven-Textured-Leather-Tote",
		img: images.LoeweWovenTexturedLeatherTote,
		metaDesc: "Discover the Loewe Woven Textured-Leather Tote with EnterOffer Marketplace",
		keywords: "Loewe, Woven, Textured, leather, tote, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 4800, condition: "New", location: "Strathfield" },
			{ offer: 4200, condition: "New", location: "Wentworthville" },
			{ offer: 4200, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 5100, condition: "New", location: "Australia" }],
		followers: 7,
		category: "Accessories",
	},
	{
		productName: "Loewe Flamenco Leather Clutch",
		highestOffer: "$2,900",
		lowestAsk: "$3,150",
		id: "Loewe-Flamenco-Leather-Clutch",
		img: images.LoeweFlamencoLeatherClutch,
		metaDesc: "Discover the Loewe Flamenco Leather Clutch with EnterOffer Marketplace",
		keywords: "Loewe, Woven, Textured, leather, tote, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2900, condition: "New", location: "Newcastle" },
			{ offer: 2890, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 3150, condition: "New", location: "Australia" }],
		followers: 2,
		category: "Accessories",
	},
	{
		productName: "Balenciaga Navy New Medium Cabas",
		highestOffer: "$1,000",
		lowestAsk: "$1,230",
		id: "Balenciaga-Navy-New-Medium-Cabas",
		img: images.BalenciagaNavyNewMediumCabas,
		metaDesc: "Discover the Balenciaga Navy New Medium Cabas with EnterOffer Marketplace",
		keywords: "Balenciaga, Navy, Cabas,bag, women, buy, sell",
		desc: "",
		sellOffers: [{ offer: 1000, condition: "New", location: "North Sydney" }],
		buyOffers: [{ offer: 1230, condition: "New", location: "Australia" }],
		followers: 10,
		category: "Accessories",
	},
	{
		productName: "Loewe Large Leather-Trimmed Woven Raffia Tote",
		highestOffer: "$980",
		lowestAsk: "$1,070",
		id: "Loewe-Large-Leather-Trimmed-Woven-Raffia-Tote",
		img: images.LoeweLargeLeatherTrimmedWovenRaffiaTote,
		metaDesc: "Discover the Loewe Large Leather-Trimmed Woven Raffia Tote with EnterOffer Marketplace",
		keywords: "Loewe, Leather, Woven,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 980, condition: "New", location: "Burwood" },
			{ offer: 800, condition: "New", location: "Redfern" },
			{ offer: 800, condition: "New", location: "Australia" },
			{ offer: 800, condition: "New", location: "Epping" },
		],
		buyOffers: [{ offer: 1070, condition: "New", location: "Australia" }],
		followers: 0,
		category: "Accessories",
	},
	{
		productName: "Louis Vuitton Pochette Métis Bag",
		highestOffer: "$2,250",
		lowestAsk: "$3,350",
		id: "Louis-Vuitton-Pochette-Métis-Bag",
		img: images.LouisVuittonPochetteMétisBag,
		metaDesc: "Discover the Louis Vuitton Pochette Métis Bag with EnterOffer Marketplace",
		keywords: "Louis, Vuitton, Pochette,Métis ,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2250, condition: "New", location: "Mortlake" },
			{ offer: 2200, condition: "New", location: "Australia" },
			{ offer: 2180, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 3350, condition: "New", location: "Australia" }],
		followers: 19,
		category: "Accessories",
	},
	{
		productName: "Dior Oblique Saddle Clutch",
		highestOffer: "$2,600",
		lowestAsk: "$3,020",
		id: "Dior-Oblique-Saddle-Clutch",
		img: images.DiorObliqueSaddleClutch,
		metaDesc: "Discover the Dior Oblique Saddle Clutch with EnterOffer Marketplace",
		keywords: "Dior, Oblique, Saddle,Clutch ,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2600, condition: "New", location: "Concord" },
			{ offer: 1800, condition: "New", location: "East Lindfield" },
		],
		buyOffers: [{ offer: 3020, condition: "New", location: "Australia" }],
		followers: 20,
		category: "Accessories",
	},
	{
		productName: "Dior Saddle Calfskin",
		highestOffer: "$2,700",
		lowestAsk: "$3,350",
		id: "Dior-Saddle-Calfskin",
		img: images.DiorSaddleCalfskin,
		metaDesc: "Discover the Dior Oblique Saddle Clutch with EnterOffer Marketplace",
		keywords: "Dior, Oblique, Saddle,Clutch ,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2600, condition: "New", location: "Concord" },
			{ offer: 1800, condition: "New", location: "East Lindfield" },
		],
		buyOffers: [{ offer: 3530, condition: "New", location: "Australia" }],
		followers: 2,
		category: "Accessories",
	},
	{
		productName: "Bulgari Serpenti Forever Crossbody Bag",
		highestOffer: "$2,150",
		lowestAsk: "$2,575",
		id: "Bulgari-Serpenti-Forever-Crossbody-Bag",
		img: images.BulgariSerpentiForeverCrossbodyBag,
		metaDesc: "Discover the Bulgari Serpenti Forever Crossbody Bag with EnterOffer Marketplace",
		keywords: "Bulgari, Serpenti, Forever,Crossbody ,bag, women, buy, sell",
		desc: "",
		sellOffers: [{ offer: 2150, condition: "New", location: "Chatswood" }],
		buyOffers: [{ offer: 2575, condition: "New", location: "Australia" }],
		followers: 4,
		category: "Accessories",
	},
	{
		productName: "Chloe Faye Medium Leather and Suede Shoulder Bag",
		highestOffer: "$1,150",
		lowestAsk: "$1,400",
		id: "Chloe-Faye-Medium-Leather-and-Suede-Shoulder-Bag",
		img: images.ChloeFayeMediumLeatherandSuedeShoulderBag,
		metaDesc: "Discover the Chloe Faye Medium Leather and Suede Shoulder Bag with EnterOffer Marketplace",
		keywords: "Chloe, Faye, Leather,Shoulder ,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 1150, condition: "New", location: "Sydney CBD" },
			{ offer: 900, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 1400, condition: "New", location: "Australia" }],
		followers: 11,
		category: "Accessories",
	},
	{
		productName: "Bottega Veneta Jodie Knotted Intrecciato Leather Tote",
		highestOffer: "$3,000",
		lowestAsk: "$3,530",
		id: "Bottega-Veneta-Jodie-Knotted-Intrecciato-Leather-Tote",
		img: images.BottegaVenetaJodieKnottedIntrecciatoLeatherTote,
		metaDesc: "Discover the Bottega Veneta Jodie Knotted Intrecciato Leather Tote with EnterOffer Marketplace",
		keywords: "Bottega, Veneta, Jodie,Knotted,Intrecciato ,Tote,Leather,bag, women, buy, sell",
		desc: "",
		sellOffers: [{ offer: 3000, condition: "New", location: "Burwood" }],
		buyOffers: [{ offer: 3530, condition: "New", location: "Australia" }],
		followers: 0,
		category: "Accessories",
	},
	{
		productName: "Bottega Veneta The Pouch Large Gathered Leather Clutch",
		highestOffer: "$3,150",
		lowestAsk: "$3,730",
		id: "Bottega-Veneta-The-Pouch-Large-Gathered-Leather-Clutch",
		img: images.BottegaVenetaThePouchLargeGatheredLeatherClutch,
		metaDesc: "Discover the Bottega Veneta The Pouch Large Gathered Leather Clutch with EnterOffer Marketplace",
		keywords: "Bottega, Veneta, Pouch,Leather,Clutch ,Leather,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 3150, condition: "New", location: "Rhodes" },
			{ offer: 2800, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 3730, condition: "New", location: "Australia" }],
		followers: 3,
		category: "Accessories",
	},
	{
		productName: "Fendi Baguette",
		highestOffer: "$890",
		lowestAsk: "$1,050",
		id: "Fendi-Baguette",
		img: images.FendiBaguette,
		metaDesc: "Discover the Fendi Baguette with EnterOffer Marketplace",
		keywords: "Fendi, Baguette,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 890, condition: "New", location: "Stratfield" },
			{ offer: 700, condition: "New", location: "Homebush" },
			{ offer: 500, condition: "New", location: "Rhodes" },
		],
		buyOffers: [{ offer: 1050, condition: "New", location: "Australia" }],
		followers: 1,
		category: "Accessories",
	},
	{
		productName: "Saint Laurent Loulou Medium Quilted Leather Shoulder Bag",
		highestOffer: "$2,900",
		lowestAsk: "$3,065",
		id: "Saint-Laurent-Loulou-Medium-Quilted-Leather-Shoulder-Bag",
		img: images.SaintLaurentLoulouMediumQuiltedLeatherShoulderBag,
		metaDesc: "Discover the Saint Laurent Loulou Medium Quilted Leather Shoulder Bag with EnterOffer Marketplace",
		keywords: "Saint, Laurent,Loulou,Quilted,Leather,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2900, condition: "New", location: "North Rocks" },
			{ offer: 2100, condition: "New", location: "Toongabbie" },
			{ offer: 1900, condition: "New", location: "Toongabbie" },
			{ offer: 1875, condition: "New", location: "Toongabbie" },
		],
		buyOffers: [{ offer: 3065, condition: "New", location: "Australia" }],
		followers: 22,
		category: "Accessories",
	},
	{
		productName: "Saint Laurent Kate Leather Shoulder Bag",
		highestOffer: "$1,850",
		lowestAsk: "$2,300",
		id: "Saint-Laurent-Kate-Leather-Shoulder-Bag",
		img: images.SaintLaurentKateLeatherShoulderBag,
		metaDesc: "Discover the Saint Laurent Kate Leather Shoulder Bag with EnterOffer Marketplace",
		keywords: "Saint, Laurent,Kate,Leather,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 1850, condition: "New", location: "Burwood" },
			{ offer: 1720, condition: "New", location: "Sydney CBD" },
		],
		buyOffers: [{ offer: 2300, condition: "New", location: "Australia" }],
		followers: 33,
		category: "Accessories",
	},
	{
		productName: "Dior Oblique Embroidered Book Tote",
		highestOffer: "$3,950",
		lowestAsk: "$4,200",
		id: "Dior-Oblique-Embroidered-Book-Tote",
		img: images.DiorObliqueEmbroideredBookTote,
		metaDesc: "Discover the Dior Oblique Embroidered Book Tote with EnterOffer Marketplace",
		keywords: "Dior, Oblique,Embroidered,Book, Tote,bag, women, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 3950, condition: "New", location: "Burwood" },
			{ offer: 3800, condition: "New", location: "Waterloo" },
		],
		buyOffers: [{ offer: 4200, condition: "New", location: "Australia" }],
		followers: 24,
		category: "Accessories",
	},
	{
		productName: "Nintendo Switch Carry Case + Screen Protector",
		highestOffer: "$20",
		lowestAsk: "$29",
		id: "Nintendo-Switch-Carry-Case-Screen-Protector",
		img: images.NintendoSwitchCarryCaseScreenProtector,
		metaDesc: "Shop the Nintendo Switch Carry Case + Screen Protector with EnterOffer Marketplace",
		keywords: "Nintendo, Carry,Case,Switch, Protector, buy, sell",
		desc: "Protect and carry around your Nintendo Switch Lite console on the go with this official Nintendo Carrying Case and Screen Protector.",
		sellOffers: [{ offer: 20, condition: "New", location: "Chatswood" }],
		buyOffers: [{ offer: 29, condition: "New", location: "Australia" }],
		followers: 13,
		category: "Gaming",
	},
	{
		productName: "Logitech G29 Driving Force Racing Wheel for PlayStation 4",
		highestOffer: "$400",
		lowestAsk: "$499",
		id: "Logitech-G29-Driving-Force-Racing-Wheel-for-PlayStation-4",
		img: images.LogitechG29DrivingForceRacingWheelforPlayStation4,
		metaDesc: "Shop the Logitech G29 Driving Force Racing Wheel for PlayStation 4 with EnterOffer Marketplace",
		keywords: "PlayStation,PS4, Logitech,Driving,Racing, Wheel, buy, sell",
		desc: "Never race with a regular controller again after you add Driving Force to your controller selection.",
		sellOffers: [
			{ offer: 400, condition: "New", location: "Sydney CBD" },
			{ offer: 300, condition: "Used", location: "Hurstville" },
		],
		buyOffers: [{ offer: 499, condition: "New", location: "Australia" }],
		followers: 17,
		category: "Gaming",
	},
	{
		productName: "Astro A20 Wireless Headset",
		highestOffer: "$200",
		lowestAsk: "$249",
		id: "Astro-A20",
		img: images.AstroA20,
		metaDesc: "Shop the Astro A20 Wireless Headset with EnterOffer Marketplace",
		keywords: "AstroA20,headset,wireless, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 200, condition: "New", location: "Parramatta" },
			{ offer: 120, condition: "Used", location: "Croydon" },
		],
		buyOffers: [{ offer: 249, condition: "New", location: "Australia" }],
		followers: 63,
		category: "Gaming",
	},
	{
		productName: "Alienware 34 Curved Gaming Monitor",
		highestOffer: "$2,000",
		lowestAsk: "$2,499",
		id: "Alienware-34-Curved-Gaming-Monitor",
		img: images.Alienware34CurvedGamingMonitor,
		metaDesc: "Shop the Alienware 34 Curved Gaming Monitor with EnterOffer Marketplace",
		keywords: "Alienware,Curved,Gaming, buy, sell",
		desc: "",
		sellOffers: [{ offer: 2000, condition: "New", location: "Redfern" }],
		buyOffers: [{ offer: 2499, condition: "New", location: "Australia" }],
		followers: 128,
		category: "Gaming",
	},
	{
		productName: "Ergolux Kevlar Gaming Chair (Blue)",
		highestOffer: "$150",
		lowestAsk: "$159",
		id: "Ergolux-Kevlar-Gaming-Chair-blue",
		img: images.ErgoluxKevlar,
		metaDesc: "Shop the Ergolux Kevlar Gaming Chair with EnterOffer Marketplace",
		keywords: "Ergolux, Kevlar,Gaming, Chair, blue, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 150, condition: "New", location: "Concord" },
			{ offer: 120, condition: "New", location: "Concord" },
			{ offer: 120, condition: "New", location: "Strathfield" },
		],
		buyOffers: [{ offer: 159, condition: "New", location: "Australia" }],
		followers: 41,
		category: "Gaming",
	},
	{
		productName: "Logitech Performance MK850 Wireless Keyboard & Mouse Combo",
		highestOffer: "$150",
		lowestAsk: "$180",
		id: "Logitech-Performance-MK850-Wireless-Keyboard-and-Mouse",
		img: images.LogitechPerformanceMK850WirelessKeyboardMouse,
		metaDesc: "Shop the Logitech Performance MK850 Wireless Keyboard & Mouse Combo with EnterOffer Marketplace",
		keywords: "Logitech, Performance,MK850, Wireless, Keyboard, Mouse, Combo, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 150, condition: "New", location: "Vaucluse" },
			{ offer: 120, condition: "New", location: "Australia" },
			{ offer: 115, condition: "New", location: "Penrith" },
		],
		buyOffers: [{ offer: 180, condition: "New", location: "Australia" }],
		followers: 276,
		category: "Gaming",
	},
	{
		productName: "Logitech MX Sound Bluetooth Speakers",
		highestOffer: "$130",
		lowestAsk: "$164",
		id: "Logitech-MX-Sound-Bluetooth-Speakers",
		img: images.LogitechMXSound,
		metaDesc: "Shop the Logitech MX Sound Bluetooth Speakers with EnterOffer Marketplace",
		keywords: "Logitech, MX,Sound, Wireless, bluetooth, speakers, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 130, condition: "New", location: "Roseville" },
			{ offer: 114, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 164, condition: "New", location: "Australia" }],
		followers: 38,
		category: "Gaming",
	},
	{
		productName: "PlayStation 4 500GB Jet Black Console",
		highestOffer: "$410",
		lowestAsk: "$439",
		id: "PlayStation-4-500GB-Jet-Black-Console",
		img: images.PS4,
		metaDesc: "Shop the PlayStation 4 500GB Jet Black Console with EnterOffer Marketplace",
		keywords: "PlayStation, 500GB,Black, Console, PS4, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 390, condition: "New", location: "Ryde" },
			{ offer: 410, condition: "New", location: "Rhodes" },
		],
		buyOffers: [{ offer: 439, condition: "New", location: "Australia" }],
		followers: 165,
		category: "Gaming",
	},
	{
		productName: "Legends of Zelda Nintendo Switch Skin",
		highestOffer: "$15",
		lowestAsk: "$20",
		id: "Zelda-Nintendo-Switch-Skin",
		img: images.ZeldaNintendoSwitchSkin,
		metaDesc: "Shop the Legends of Zelda Nintendo Switch Skin with EnterOffer Marketplace",
		keywords: "Zelda, Nintendo,Switch, Skin, Console, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 15, condition: "New", location: "Parramatta" },
			{ offer: 12.5, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 20, condition: "New", location: "Australia" }],
		followers: 55,
		category: "Gaming",
	},
	{
		productName: "Catdalf Nintendo Switch Skin",
		highestOffer: "$16",
		lowestAsk: "$20",
		id: "Catdalf-Nintendo-Switch-Skin",
		img: images.CatdalfNintendoSwitchSkin,
		metaDesc: "Shop the Catdalf Nintendo Switch Skin with EnterOffer Marketplace",
		keywords: "Catdalf, Cats, Gandalf, Nintendo,Switch, Skin, Console, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 16, condition: "New", location: "Sydney CBD" },
			{ offer: 14.5, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 20, condition: "New", location: "Australia" }],
		followers: 73,
		category: "Gaming",
	},
	{
		productName: "Marvel of Zelda Nintendo Switch Skin",
		highestOffer: "$15",
		lowestAsk: "$19",
		id: "Marvel-Nintendo-Switch-Skin",
		img: images.MarvelNintendoSwitchSkin,
		metaDesc: "Shop the Marvel Nintendo Switch Skin with EnterOffer Marketplace",
		keywords: "Marvel, Nintendo,Switch, Skin, Console, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 15, condition: "New", location: "Redfern" },
			{ offer: 16, condition: "New", location: "Redfern" },
		],
		buyOffers: [{ offer: 19, condition: "New", location: "Australia" }],
		followers: 81,
		category: "Gaming",
	},
	{
		productName: "Star Wars of Zelda Nintendo Switch Skin",
		highestOffer: "$18",
		lowestAsk: "$20",
		id: "Star-Wars-Nintendo-Switch-Skin",
		img: images.StarWarsNintendoSwitchSkin,
		metaDesc: "Shop the Star Wars Nintendo Switch Skin with EnterOffer Marketplace",
		keywords: "Star Wars, Nintendo,Switch, Skin, Console, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 18, condition: "New", location: "Epping" },
			{ offer: 12.5, condition: "New", location: "Australia" },
			{ offer: 13.8, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 20, condition: "New", location: "Australia" }],
		followers: 92,
		category: "Gaming",
	},
	{
		productName: "Super Smash Bros Nintendo Switch Skin",
		highestOffer: "$13",
		lowestAsk: "$15",
		id: "Super-Smash-Bros-Nintendo-Switch-Skin",
		img: images.SuperSmashBrosNintendoSwitchSkin,
		metaDesc: "Shop the Super Smash Bros Nintendo Switch Skin with EnterOffer Marketplace",
		keywords: "Zelda, Nintendo,Switch, Skin, Console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 13, condition: "New", location: "Parramatta" }],
		buyOffers: [{ offer: 15, condition: "New", location: "Australia" }],
		followers: 92,
		category: "Gaming",
	},
	{
		productName: "Fantail Sunglasses",
		highestOffer: "$280",
		lowestAsk: "$320",
		id: "Fantail-Sunglasses",
		img: images.Fantail,
		metaDesc: "Shop the Fantail Sunglasses with EnterOffer Marketplace",
		keywords: "Fantail, Costa del Mar,Sunglasses, buy, sell",
		desc: "",
		sellOffers: [{ offer: 280, condition: "New", location: "Vaucluse" }],
		buyOffers: [{ offer: 320, condition: "New", location: "Australia" }],
		followers: 101,
		category: "Sunglasses",
	},
	{
		productName: "Oakley Flak™ 2.0 XL Sunglasses",
		highestOffer: "$190",
		lowestAsk: "$228",
		id: "Oakley-Flak-XL-Sunglasses",
		img: images.OakleyFlakXL,
		metaDesc: "Shop the Oakley Flak™ 2.0 XL with EnterOffer Marketplace",
		keywords: "Oakley, Flak,Sunglasses, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 190, condition: "New", location: "Vaucluse" },
			{ offer: 180, condition: "New", location: "Wynyard" },
		],
		buyOffers: [{ offer: 228, condition: "New", location: "Australia" }],
		followers: 104,
		category: "Sunglasses",
	},
	{
		productName: "Rayban Wayfarer Classic",
		highestOffer: "$140",
		lowestAsk: "$164",
		id: "Rayban-Wayfarer-Classic-Black",
		img: images.RaybanWayfarerBlack,
		metaDesc: "Shop the Rayban Wayfarer Classic with EnterOffer Marketplace",
		keywords: "Rayban, Wayfarers,Sunglasses, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 140, condition: "New", location: "Rhodes" },
			{ offer: 157, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 164, condition: "New", location: "Australia" }],
		followers: 120,
		category: "Sunglasses",
	},
	{
		productName: "Rayban Aviator Classic",
		highestOffer: "$145",
		lowestAsk: "$164",
		id: "Rayban-Aviator-Classic-Black",
		img: images.RaybanAviatorBlack,
		metaDesc: "Shop the Rayban Aviator Classic with EnterOffer Marketplace",
		keywords: "Rayban, Aviator,Sunglasses, buy, sell",
		desc: "",
		sellOffers: [{ offer: 145, condition: "New", location: "Guildford" }],
		buyOffers: [{ offer: 164, condition: "New", location: "Australia" }],
		followers: 310,
		category: "Sunglasses",
	},
	{
		productName: "Hoover Dual Steam Plus Steam Mop",
		highestOffer: "$200",
		lowestAsk: "$279",
		id: "Hoover-Dual-Steam-Plus-Steam-Mop",
		img: images.HooverDualSteamPlusSteamMop,
		metaDesc: "Shop the Hoover Dual Steam Plus Steam Mop with EnterOffer Marketplace",
		keywords: "Hoover, Dual,Steam, Mop,cleaner, floor, buy, sell",
		desc: "",
		sellOffers: [{ offer: 200, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 279, condition: "New", location: "Australia" }],
		followers: 10,
		category: "Appliances",
	},
	{
		productName: "Karcher SC3 EasyFix Premium Steam Cleaner",
		highestOffer: "$267",
		lowestAsk: "$318",
		id: "Karcher-SC3-EasyFix-Premium-Steam-Cleaner",
		img: images.KarcherSC3EasyFixPremiumSteamCleaner,
		metaDesc: "Shop the Karcher SC3 EasyFix Premium Steam Cleaner with EnterOffer Marketplace",
		keywords: "Karcher, SC3,SC, EasyFix,premium, steam, cleaner, floor, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 267, condition: "New", location: "Sydenham" },
			{ offer: 250, condition: "New", location: "Manly" },
		],
		buyOffers: [{ offer: 318, condition: "New", location: "Australia" }],
		followers: 20,
		category: "Appliances",
	},
	{
		productName: "Karacher SC5 Easyfix Premium Steam Cleaner",
		highestOffer: "$267",
		lowestAsk: "$787",
		id: "Karacher-SC5-Easyfix-Premium-Steam-Cleaner",
		img: images.KaracherSC5EasyfixPremium,
		metaDesc: "Shop the Karacher SC5 Easyfix Premium Steam Cleaner with EnterOffer Marketplace",
		keywords: "Karcher, SC5,SC, EasyFix,premium, steam, cleaner, floor, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 267, condition: "New", location: "Sydenham" },
			{ offer: 250, condition: "New", location: "Manly" },
		],
		buyOffers: [
			{ offer: 787, condition: "New", location: "Australia" },
			{ offer: 899, condition: "New", location: "Australia" },
		],
		followers: 21,
		category: "Appliances",
	},
	{
		productName: "Shark Klik n’Flip Automatic Steam Mop",
		highestOffer: "$135",
		lowestAsk: "$189",
		id: "Shark-Klik-n’Flip-Automatic-Steam-Mop",
		img: images.SharkKliknFlipAutomaticSteamMop,
		metaDesc: "Shop the Shark Klik n’Flip Automatic Steam Mop with EnterOffer Marketplace",
		keywords: "Shark,Klik, Klik n'Flip,Automatic, Steam,mop, cleaner, floor, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 135, condition: "New", location: "Newtown" },
			{ offer: 65, condition: "Used", location: "Glebe" },
		],
		buyOffers: [{ offer: 189, condition: "New", location: "Australia" }],
		followers: 10,
		category: "Appliances",
	},
	{
		productName: "Tiffany Makers Medium Slice Ring In Sterling Silver",
		highestOffer: "$450",
		lowestAsk: "$580",
		id: "tiffany-makers-medium-slice-ring-in-sterling-silver",
		img: images.TiffanyMakersMediumSliceRingInSterlingSilver,
		metaDesc: "Shop the Tiffany Makers Medium Slice Ring In Sterling Silver with EnterOffer Marketplace",
		keywords: "Tiffany ,Makers, Slice,Ring, Sterling Silver, buy, sell",
		desc: "",
		sellOffers: [{ offer: 450, condition: "New", location: "Penrith" }],
		buyOffers: [{ offer: 580, condition: "New", location: "Australia" }],
		followers: 26,
		category: "Jewellery",
	},
	{
		productName: "Thomas Sabo Ring Black Cat",
		highestOffer: "$240",
		lowestAsk: "$269",
		id: "thomas-sabo-ring-black-cat",
		img: images.ThomasSaboRingBlackCat,
		metaDesc: "Shop the Thomas Sabo Ring Black Cat with EnterOffer Marketplace",
		keywords: "Thomas Sabo ,Black Cat,Ring, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 240, condition: "New", location: "Concord" },
			{ offer: 200, condition: "New", location: "Croydon" },
			{ offer: 200, condition: "New", location: "Hurstville" },
			{ offer: 190, condition: "New", location: "Australia" },
		],
		buyOffers: [{ offer: 269, condition: "New", location: "Australia" }],
		followers: 23,
		category: "Jewellery",
	},
	{
		productName: "Cartier Love Ring Yellow Gold",
		highestOffer: "$2,340",
		lowestAsk: "$2,480",
		id: "cartier-love-ring-yellow-gold",
		img: images.CartierLoveRingYellowGold,
		metaDesc: "Shop the Cartier Love Ring Yellow Gold with EnterOffer Marketplace",
		keywords: "cartier,Love,Ring, yellow gold, gold, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 2340, condition: "New", location: "Botany Bay" },
			{ offer: 2000, condition: "New", location: "Alexandria" },
		],
		buyOffers: [{ offer: 2480, condition: "New", location: "Australia" }],
		followers: 40,
		category: "Jewellery",
	},
	{
		productName: "Nintendo Switch Dock Set",
		highestOffer: "$110",
		lowestAsk: "$120",
		id: "nintendo-switch-dock-set",
		img: images.NintendoSwitchDockSet,
		metaDesc: "Shop the Nintendo Switch Dock Set with EnterOffer Marketplace",
		keywords: "Nintendo Switch ,Dock set, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 110, condition: "New", location: "Narrawee" },
			{ offer: 100, condition: "New", location: "Vaucluse" },
		],
		buyOffers: [{ offer: 120, condition: "New", location: "Australia" }],
		followers: 110,
		category: "Gaming",
	},
	{
		productName: "Nintendo Switch Joy-Con Controllers",
		highestOffer: "$105",
		lowestAsk: "$119",
		id: "nintendo-switch-joy-con-controllers",
		img: images.NintendoSwitchJoyConControllers,
		metaDesc: "Shop the Nintendo Switch Joy-Con Controllers with EnterOffer Marketplace",
		keywords: "Nintendo Switch,Joy Con, Controller, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 105, condition: "New", location: "Rhodes" },
			{ offer: 100, condition: "New", location: "Mascot" },
		],
		buyOffers: [{ offer: 119, condition: "New", location: "Australia" }],
		followers: 201,
		category: "Gaming",
	},
	{
		productName: "Nintendo Switch Lite Console",
		highestOffer: "$280",
		lowestAsk: "$329",
		id: "nintendo-switch-lite",
		img: images.NintendoSwitchLite,
		metaDesc: "Shop the Nintendo Switch Lite with EnterOffer Marketplace",
		keywords: "Nintendo Switch Lite, console, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 280, condition: "New", location: "Narrawee" },
			{ offer: 250, condition: "New", location: "Alexandria" },
		],
		buyOffers: [{ offer: 329, condition: "New", location: "Australia" }],
		followers: 157,
		category: "Gaming",
	},
	{
		productName: "Nintendo Switch Pro Controller",
		highestOffer: "$70",
		lowestAsk: "$89",
		id: "nintendo-switch-pro-controller",
		img: images.NintendoSwitchProController,
		metaDesc: "Shop the Nintendo Switch Pro Controller with EnterOffer Marketplace",
		keywords: "Nintendo Switch Pro, controller, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 70, condition: "New", location: "Artarmon" }],
		buyOffers: [{ offer: 89, condition: "New", location: "Rhodes" }],
		followers: 187,
		category: "Gaming",
	},
	{
		productName: "Streets of Rage 4",
		highestOffer: "$50",
		lowestAsk: "$60",
		id: "streets-of-rage-4",
		img: images.StreetsOfRage4,
		metaDesc: "Shop the Streets of Rage 4 with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, fighting, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 50, condition: "New", location: "Lindfield" }],
		buyOffers: [{ offer: 60, condition: "New", location: "Sydney CBD" }],
		followers: 104,
		category: "Gaming",
	},
	{
		productName: "Ring Fit Adventure",
		highestOffer: "$100",
		lowestAsk: "$125",
		id: "ring-fit-adventure",
		img: images.RingFitAdventure,
		metaDesc: "Shop the Ring Fit Adventure with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 100, condition: "New", location: "Chatswood" }],
		buyOffers: [{ offer: 125, condition: "New", location: "Australia" }],
		followers: 140,
		category: "Gaming",
	},
	{
		productName: "Animal Crossing New Horizons",
		highestOffer: "$60",
		lowestAsk: "$80",
		id: "animal-crossing-new-horizons",
		img: images.AnimalCrossingNewHorizons,
		metaDesc: "Shop the Animal Crossing New Horizons with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 60, condition: "New", location: "Eastwood" }],
		buyOffers: [{ offer: 80, condition: "New", location: "Ryde" }],
		followers: 143,
		category: "Gaming",
	},
	{
		productName: "Luigi's Mansion 3",
		highestOffer: "$70",
		lowestAsk: "$80",
		id: "luigis-mansion-3",
		img: images.LuigisMansion3,
		metaDesc: "Shop the Luigi's Mansion 3 with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 70, condition: "New", location: "Parramatta" }],
		buyOffers: [{ offer: 80, condition: "New", location: "Epping" }],
		followers: 79,
		category: "Gaming",
	},
	{
		productName: "Super Mario Maker 2",
		highestOffer: "$70",
		lowestAsk: "$80",
		id: "super-mario-maker-2",
		img: images.SuperMarioMaker2,
		metaDesc: "Shop the Super Mario Maker 2 with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, puzzle, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 70, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 80, condition: "New", location: "Strathfield" }],
		followers: 40,
		category: "Gaming",
	},
	{
		productName: "Splatoon 2",
		highestOffer: "$70",
		lowestAsk: "$80",
		id: "splatoon-2",
		img: images.Splatoon2,
		metaDesc: "Shop the Splatoon 2 with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 70, condition: "New", location: "North Sydney" }],
		buyOffers: [{ offer: 80, condition: "New", location: "Burwood" }],
		followers: 31,
		category: "Gaming",
	},
	{
		productName: "Stealth Case for Nintendo Switch (Mario)",
		highestOffer: "$15",
		lowestAsk: "$19",
		id: "Stealth-Case-For-Nintendo-Switch-Mario",
		img: images.StealthCaseForNintendoSwitchMario,
		metaDesc: "Shop the Stealth Case for Nintendo Switch (Mario) with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 15, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 19, condition: "New", location: "Penrith" }],
		followers: 31,
		category: "Gaming",
	},
	{
		productName: "Official Nintendo Switch Carrying Case & Screen Protector",
		highestOffer: "$20",
		lowestAsk: "$29",
		id: "Official-Nintendo-Switch-Carrying-Case-Screen-Protector",
		img: images.OfficialNintendoSwitchCarryingCaseScreenProtector,
		metaDesc: "Shop the Official Nintendo Switch Carrying Case & Screen Protector with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 20, condition: "New", location: "Ryde" }],
		buyOffers: [{ offer: 29, condition: "New", location: "Lidcombe" }],
		followers: 61,
		category: "Gaming",
	},
	{
		productName: "3-in-1 Folio Case For Nintendo Switch",
		highestOffer: "$25",
		lowestAsk: "$34",
		id: "3-in-1-Folio-Case-For-Nintendo-Switch",
		img: images.FolioCaseForNintendoSwitch,
		metaDesc: "Shop the 3-in-1 Folio Case For Nintendo Switch with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 25, condition: "New", location: "Ryde" }],
		buyOffers: [{ offer: 34, condition: "New", location: "Burwood" }],
		followers: 54,
		category: "Gaming",
	},
	{
		productName: "RDS Poké Ball Case For Nintendo Switch",
		highestOffer: "$25",
		lowestAsk: "$29",
		id: "RDS-Poké-Ball-Case-For-Nintendo-Switch",
		img: images.RDSPokéBallCaseForNintendoSwitch,
		metaDesc: "Shop the RDS Poké Ball Case For Nintendo Switch with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 25, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 29, condition: "New", location: "Concord" }],
		followers: 63,
		category: "Gaming",
	},
	{
		productName: "Super Mario Hori Vault Nintendo Switch Case",
		highestOffer: "$18",
		lowestAsk: "$25",
		id: "Super-Mario-Hori-Vault-Nintendo-Switch-Case",
		img: images.SuperMarioHoriVaultNintendoSwitchCase,
		metaDesc: "Shop the Super Mario Hori Vault Nintendo Switch Case with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 18, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 25, condition: "New", location: "Cabarita" }],
		followers: 31,
		category: "Gaming",
	},
	{
		productName: "The Legend of Zelda: Breath of the Wild HORI Slim Pouch",
		highestOffer: "$19",
		lowestAsk: "$25",
		id: "The-Legend-of-Zelda-Breath-of-the-Wild-HORI-Slim-Pouch",
		img: images.FolioCaseForNintendoSwitch,
		metaDesc: "Shop the The Legend of Zelda: Breath of the Wild HORI Slim Pouch with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 19, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 25, condition: "New", location: "Burwood" }],
		followers: 31,
		category: "Gaming",
	},
	{
		productName: "Luigi Camo Nintendo Switch Slim Travel Case",
		highestOffer: "$15",
		lowestAsk: "$25",
		id: "Luigi-Camo-Nintendo-Switch-Slim-Travel-Case",
		img: images.LuigiCamoNintendoSwitchSlimTravelCase,
		metaDesc: "Shop the Luigi Camo Nintendo Switch Slim Travel Case with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, slim travel case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 15, condition: "New", location: "Ashfield" }],
		buyOffers: [{ offer: 25, condition: "New", location: "Croydon" }],
		followers: 69,
		category: "Gaming",
	},
	{
		productName: "Nintendo Switch Deluxe Traveller Case",
		highestOffer: "$50",
		lowestAsk: "$60",
		id: "Nintendo-Switch-Deluxe-Traveller-Case",
		img: images.NintendoSwitchDeluxeTravellerCase,
		metaDesc: "Shop the Nintendo Switch Deluxe Traveller Case with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console,deluxe traveller case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 50, condition: "New", location: "Redfern" }],
		buyOffers: [{ offer: 60, condition: "New", location: "Burwood" }],
		followers: 101,
		category: "Gaming",
	},
	{
		productName: "Nintendo Switch Premium Zelda Alumi Case",
		highestOffer: "$25",
		lowestAsk: "$29",
		id: "Nintendo-Switch-Premium-Zelda-Alumi-Case",
		img: images.NintendoSwitchPremiumZeldaAlumiCase,
		metaDesc: "Shop the Nintendo Switch Premium Zelda Alumi Case with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 25, condition: "New", location: "Eastwood" }],
		buyOffers: [{ offer: 29, condition: "New", location: "Burwood" }],
		followers: 25,
		category: "Gaming",
	},
	{
		productName: "PDP Commuter Case - Elite Edition For Nintendo Switch & Switch Lite",
		highestOffer: "$35",
		lowestAsk: "$50",
		id: "PDP-Commuter-Case-Elite-Edition-For-Nintendo-Switch-Switch-Lite",
		img: images.PDPCommuterCaseEliteEditionForNintendoSwitchSwitchLite,
		metaDesc: "Shop the PDP Commuter Case - Elite Edition For Nintendo Switch & Switch Lite with EnterOffer Marketplace",
		keywords: "Nintendo Switch, Swithc Lite, PDP, Commuter, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 35, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 50, condition: "New", location: "Burwood" }],
		followers: 131,
		category: "Gaming",
	},
	{
		productName: "Nintendo Switch GoPLay Game Traveller Pack",
		highestOffer: "$39",
		lowestAsk: "$49",
		id: "Nintendo-Switch-GoPLay-Game-Traveller-Pack",
		img: images.NintendoSwitchGoPLayGameTravellerPack,
		metaDesc: "Shop the Nintendo Switch GoPLay Game Traveller Pack with EnterOffer Marketplace",
		keywords: "Nintendo Switch,GoPlay, Traveller, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 39, condition: "New", location: "Sydney Olympic Park" }],
		buyOffers: [{ offer: 49, condition: "New", location: "Burwood" }],
		followers: 53,
		category: "Gaming",
	},
	{
		productName: "Pikachu HORI Lux Pouch for Nintendo Switch & Nintendo Switch Lite",
		highestOffer: "$14",
		lowestAsk: "$20",
		id: "Pikachu-HORI-Lux-Pouch-for-Nintendo-Switch-Nintendo-Switch-Lite",
		img: images.PikachuHORILuxPouchforNintendoSwitchNintendoSwitchLite,
		metaDesc: "Shop the Pikachu HORI Lux Pouch for Nintendo Switch & Nintendo Switch Lite with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 14, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 20, condition: "New", location: "Granville" }],
		followers: 31,
		category: "Gaming",
	},
	{
		productName: "GT Deluxe Case - Animal Crossing Edition ",
		highestOffer: "$22",
		lowestAsk: "$29",
		id: "GT-Deluxe-Case-Animal-Crossing-Edition",
		img: images.GTDeluxeCaseAnimalCrossingEdition,
		metaDesc: "Shop the GT Deluxe Case - Animal Crossing Edition  with EnterOffer Marketplace",
		keywords: "Nintendo Switch, console, case, family, video games, console, buy, sell",
		desc: "",
		sellOffers: [{ offer: 22, condition: "New", location: "North Sydney" }],
		buyOffers: [{ offer: 29, condition: "New", location: "Alexandria" }],
		followers: 47,
		category: "Gaming",
	},
	{
		productName: "Belkin Surgemaster",
		highestOffer: "$25",
		lowestAsk: "$39",
		id: "Belkin-Surgemaster",
		img: images.BelkinSurgemaster,
		metaDesc: "Shop the Belkin Surgemaster with EnterOffer Marketplace",
		keywords: "Belkin, Surgemaster, buy, sell",
		desc: "",
		sellOffers: [{ offer: 25, condition: "New", location: "North Sydney" }],
		buyOffers: [{ offer: 39, condition: "New", location: "Alexandria" }],
		followers: 23,
		category: "Appliances",
	},
	{
		productName: "Conset 501 33 Electric Desk",
		highestOffer: "$550",
		lowestAsk: "$629",
		id: "Conset-501-33-Electric-Desk",
		img: images.Conset50133ElectricDesk,
		metaDesc: "Shop the Conset 501 33 Electric Desk with EnterOffer Marketplace",
		keywords: "Conset 501 33 Electric Desk, stand up desk, buy, sell",
		desc: "",
		sellOffers: [{ offer: 550, condition: "New", location: "Australia" }],
		buyOffers: [{ offer: 629, condition: "New", location: "Granville" }],
		followers: 4,
		category: "Office",
	},
	{
		productName: "Dell 27 Monitor P2719HC",
		highestOffer: "$380",
		lowestAsk: "$436",
		id: "Dell-27-Monitor-P2719HC",
		img: images.Dell27MonitorP2719HC,
		metaDesc: "Shop the Dell 27 Monitor P2719HC with EnterOffer Marketplace",
		keywords: "Dell 27 Monitor P2719HC, dell, monitor buy, sell",
		desc: "",
		sellOffers: [{ offer: 380, condition: "New", location: "Mascot" }],
		buyOffers: [{ offer: 436, condition: "New", location: "Alexandria" }],
		followers: 19,
		category: "Office",
	},
	{
		productName: "High Back Deluxe Ergonomic Office Chair",
		highestOffer: "$120",
		lowestAsk: "$299",
		id: "High-Back-Deluxe-Ergonomic-Office-Chair",
		img: images.HighBackDeluxeErgonomicOfficeChair,
		metaDesc: "Shop the High Back Deluxe Ergonomic Office Chair with EnterOffer Marketplace",
		keywords: "High Back Deluxe, Ergonomic, Office Chair, buy, sell",
		desc: "",
		sellOffers: [{ offer: 120, condition: "New", location: "Australia" }],
		buyOffers: [{ offer: 299, condition: "New", location: "Mascot" }],
		followers: 7,
		category: "Office",
	},
	{
		productName: "J.Burrows Shredder S360A",
		highestOffer: "$55",
		lowestAsk: "$69",
		id: "J-Burrows-Shredder-S360A",
		img: images.JBurrowsShredderS360A,
		metaDesc: "Shop the J.Burrows Shredder S360A with EnterOffer Marketplace",
		keywords: "J.Burrows Shredder S360A, paper shredder, buy, sell",
		desc: "",
		sellOffers: [{ offer: 55, condition: "New", location: "North Strathfield" }],
		buyOffers: [{ offer: 69, condition: "New", location: "Five Dock" }],
		followers: 62,
		category: "Office",
	},
	{
		productName: "Lexmark MX421ade Laser Printer",
		highestOffer: "$400",
		lowestAsk: "$442",
		id: "Lexmark-MX421ade-Laser-Printer",
		img: images.LexmarkMX421adeLaserPrinter,
		metaDesc: "Shop the Lexmark MX421ade Laser Printer with EnterOffer Marketplace",
		keywords: "Lexmark MX421ade, Laser Printer, buy, sell",
		desc: "",
		sellOffers: [{ offer: 400, condition: "New", location: "Sydney CBD" }],
		buyOffers: [{ offer: 442, condition: "New", location: "Auburn" }],
		followers: 14,
		category: "Office",
	},
	{
		productName: "Nespresso Vertuo Plus",
		highestOffer: "$160",
		lowestAsk: "$199",
		id: "Nespresso-Vertuo-Plus",
		img: images.NespressoVertuoPlus,
		metaDesc: "Shop the Nespresso Vertuo Plus with EnterOffer Marketplace",
		keywords: "Nespresso Vertuo Plus, coffee machine, buy, sell",
		desc: "",
		sellOffers: [
			{ offer: 160, condition: "New", location: "Parramatta" },
			{ offer: 150, condition: "New", location: "Harris Park" },
			{ offer: 149, condition: "New", location: "Auburn" },
		],
		buyOffers: [{ offer: 199, condition: "New", location: "Parramatta" }],
		followers: 41,
		category: "Appliances",
	},
	{
		productName: "Soho 2 Drawer Filing Cabinet",
		highestOffer: "$99",
		lowestAsk: "$115",
		id: "Soho-2-Drawer-Filing-Cabinet",
		img: images.Soho2DrawerFilingCabinet,
		metaDesc: "Shop the Soho 2 Drawer Filing Cabinet with EnterOffer Marketplace",
		keywords: "Soho 2, Drawer, Filing Cabinet, home office, buy, sell",
		desc: "",
		sellOffers: [{ offer: 99, condition: "New", location: "North Sydney" }],
		buyOffers: [{ offer: 115, condition: "New", location: "Five Dock" }],
		followers: 27,
		category: "Office",
	},
	{
		productName: "Razor Blade 15 Advanced Model - 15.6 Inch",
		highestOffer: "$3,400",
		lowestAsk: "$4,299",
		id: "Razor-Blade-15-Advanced-Model",
		img: images.RazorBlade15AdvancedModel,
		metaDesc: "Shop the Razor Blade 15 Advanced Model - 15.6 Inch with EnterOffer Marketplace",
		keywords: "Razor Blade,Advanced,Gaming, buy, sell",
		desc: "",
		sellOffers: [{ offer: 3400, condition: "New", location: "Redfern" }],
		buyOffers: [{ offer: 4299, condition: "New", location: "Australia" }],
		followers: 131,
		category: "Gaming",
	},
	{
		productName: "MSI GS65 Stealth Thin - 15.6 Inch",
		highestOffer: "$1,400",
		lowestAsk: "$1,999",
		id: "MSI-GS65-Stealth-Thin",
		img: images.MSIGS65StealthThin,
		metaDesc: "Shop the MSI GS65 Stealth Thin - 15.6 Inch with EnterOffer Marketplace",
		keywords: "MSI ,GS65 Stealth,Gaming, buy, sell",
		desc: "",
		sellOffers: [{ offer: 1400, condition: "Used", location: "Rochdale" }],
		buyOffers: [{ offer: 1999, condition: "Used", location: "Epping" }],
		followers: 53,
		category: "Gaming",
	},
	{
		productName: "Dell G3 15",
		highestOffer: "$1,459",
		lowestAsk: "$1,899",
		id: "Dell-G3-15",
		img: images.DellG315,
		metaDesc: "Shop the Dell G3 15 with EnterOffer Marketplace",
		keywords: "Dell G3 15 Laptop,Gaming, buy, sell",
		desc: "",
		sellOffers: [{ offer: 1459, condition: "New", location: "Rhodes" }],
		buyOffers: [{ offer: 1899, condition: "New", location: "Vaucluse" }],
		followers: 128,
		category: "Gaming",
	},
	{
		productName: "Asus TUF A15",
		highestOffer: "$1,500",
		lowestAsk: "$1,899",
		id: "Asus-TUF-A15",
		img: images.AsusTUFA15,
		metaDesc: "Shop the Asus TUF A15 with EnterOffer Marketplace",
		keywords: "Asus TUF A15, Laptop,Gaming, buy, sell",
		desc: "",
		sellOffers: [{ offer: 1500, condition: "New", location: "Wentworth Point" }],
		buyOffers: [{ offer: 1899, condition: "New", location: "Ryde" }],
		followers: 77,
		category: "Gaming",
	},
];

export default products;
