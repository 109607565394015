import React, { useState, useEffect } from "react";
import "../App.css";
import "typeface-nunito-sans";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MenuToggle, Notifications, Account, EOIModal } from "./";
import { AppBar, Toolbar, Button, TextField, InputAdornment, Badge } from "@material-ui/core";
import { Link } from "react-router-dom";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(2),
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(6),
		maxWidth: "1440px",
	},
	header: {
		// backgroundColor: "#5ba7d1",
		padding: "4px 0px",
		flexGrow: 1,
		zIndex: 1,
	},
	headerToolbar: {
		padding: "8px 20px",
		fontSize: "25px",
	},
	toolbar: {
		alignItems: "center",
		justifyContent: "space-between",
		margin: "auto",
		maxWidth: "1440px",
	},
	button: {
		color: "#656565",
		marginLeft: "1em",
	},
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		// textAlign: "left"
	},
	navLinks: {
		textTransform: "capitalize",
		padding: "0.5rem 1rem",
		transition: "0.3s",
		marginRight: "20px",
		"&:hover": {
			color: "#000 !important",
			backgroundColor: "transparent !important",
		},
	},
	signIn: {
		padding: "2px 20px",
		fontWeight: 600,
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4,
	},
	textField1: {
		width: 300,
		marginRight: 20,
		"& .MuiOutlinedInput-root": {
			borderRadius: "0.2em",
			"& fieldset": {
				borderColor: theme.palette.secondary.dark,
			},
			"&:hover fieldset": {
				borderColor: "#fff",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#fff",
			},
		},
		"& .MuiOutlinedInput-input": { padding: "0.4em", color: "#fff" },
	},
	textField2: {
		width: 300,
		marginRight: 20,
		"& .MuiOutlinedInput-root": {
			borderRadius: "0.2em",
			"& fieldset": {
				borderColor: theme.palette.secondary.dark,
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-input": { padding: "0.4em", borderColor: "#000" },
	},
	input: {
		color: "#fff",
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "yellow !important",
	},
}));

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: 5,
		top: 7,
		zoom: 0.75,
		height: 20,
		minWidth: 18,
		fontSize: 12,
		fontWeight: 600,
	},
}))(Badge);

export default function Header(props) {
	const classes = useStyles();
	const { mobHeader } = props;
	const [change, setChange] = useState(false);
	const [search, setSearch] = useState("");
	const [btn, setBtn] = useState();
	const [eoiOpen, setEoiOpen] = useState(false);

	const handleOpenEOI = (id) => {
		setEoiOpen(true);
		setBtn(id);
	};

	const handleCloseEOI = () => {
		setEoiOpen(false);
		setBtn("");
	};

	const changePosition = 5;

	var position = useWindowScrollPosition();

	useEffect(() => {
		if (position.y > changePosition && !change) {
			setChange(true);
		} else if (position.y <= changePosition && change) {
			setChange(false);
		}
	}, [change, position]);

	let style = {
		backgroundColor: !change && window.location.pathname === "/" ? "transparent" : "#fff",
		transition: "200ms ease",
		position: "fixed",
		right: 0,
		left: 0,
		top: 0,
	};

	let clr = !change && window.location.pathname === "/" ? "#fff" : "#929292";

	const handleChange = (event) => {
		props.search(event.target.value);
		setSearch(event.target.value);
	};

	return (
		<AppBar className={classes.header} elevation={0} position='fixed' style={style}>
			<Toolbar className={classes.headerToolbar}>
				{mobHeader ? <MenuToggle clr={clr} /> : null}
				<div className={classes.title} style={{ textAlign: mobHeader ? "right " : "left" }}>
					<Link color='inherit' to={{ pathname: "/" }} style={{ textDecoration: "none" }}>
						<LazyLoadImage
							effect='blur'
							src={
								!change && window.location.pathname === "/"
									? "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferWhite.png"
									: "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
							}
							alt='EnterOffer'
							style={{
								width: "6em",
								verticalAlign: "middle",
							}}
						/>
					</Link>
				</div>
				{mobHeader ? null : (
					<TextField
						className={!change && window.location.pathname === "/" ? classes.textField1 : classes.textField2}
						value={search}
						variant='outlined'
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchIcon style={{ color: !change && window.location.pathname === "/" ? "#fff" : "#3e3e3e" }} />
								</InputAdornment>
							),
						}}
					/>
				)}
				{mobHeader ? null : (
					<div>
						<Link color='inherit' to={props.signedIn ? { pathname: "/following" } : { hash: "#" }} style={{ textDecoration: "none" }}>
							<Button className={classes.navLinks} style={{ color: clr }} onClick={!props.signedIn ? () => props.signInModal() : null}>
								Following
							</Button>
						</Link>
						{/* <Link color='inherit' href='/orders' style={{ textDecoration: "none" }}> */}
						<Button className={classes.navLinks} style={{ color: clr }} onClick={() => handleOpenEOI(`f-navOrder`)}>
							Orders
						</Button>
						{/* </Link> */}
						{/* <Link color="inherit" to={props.signedIn ? { pathname: "/buy" } : { hash: "#" }} style={{ textDecoration: "none" }}> */}
						{/* <Button className={classes.navLinks} style={{ color: clr }} onClick={!props.signedIn ? () => handleOpenEOI(`f-navBuy`) : null}> */}
						<Button className={classes.navLinks} style={{ color: clr }} onClick={() => handleOpenEOI(`f-navBuy`)}>
							Buy
						</Button>
						{/* </Link> */}
						{/* <Link color='inherit' href='/sell' style={{ textDecoration: "none" }}> */}
						<Button className={classes.navLinks} style={{ color: clr }} onClick={() => handleOpenEOI(`f-navSell`)}>
							Sell
						</Button>
						{/* </Link> */}
						{/* <Link color='inherit' href='/notifications' style={{ textDecoration: "none" }}> */}
						{/* <Notifications clr={clr} /> */}
						{/* </Link> */}
						{props.signedIn ? (
							<Account clr={clr} change={change} initials={props.initials} />
						) : (
							<Button
								className={classes.signIn}
								variant={!change && window.location.pathname === "/" ? null : "contained"}
								style={{ color: clr }}
								disableElevation
								onClick={!props.signedIn ? () => props.signInModal() : null}
								color='primary'>
								SIGN IN
							</Button>
						)}
					</div>
				)}
			</Toolbar>
			<EOIModal open={eoiOpen} closeModal={handleCloseEOI} btn={btn} signedIn={props.signedIn} />
			{/* <SignInModal open={open} closeModal={handleCloseEOI} btn={btn} /> */}
		</AppBar>
	);
}
