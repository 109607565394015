import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import withSizes from "react-sizes";
import { Link, withRouter } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import { fb } from "../firebase";
import { ErrorSnackbar, SignInModal } from "./";

const useStyles = makeStyles((theme) => ({
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		border: "1px solid #f6f6f8",
		backgroundColor: "#f9f9f9",
	},
	cardMedia: {
		// paddingTop: "56.25%",
		marginBottom: 0,
		objectFit: "contain",
		maxHeight: 280,
		// minHeight: 300,
		filter: "brightness(0.98)",
	},
	buy: {
		backgroundColor: "#58c8f5",
		color: "#fff",
		boxShadow: "none",
		height: "100%",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#0eb1f1",
			boxShadow: "none",
		},
	},
	sell: {
		backgroundColor: "#71db80",
		color: "#fff",
		boxShadow: "none",
		height: "100%",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#47d15a",
			boxShadow: "none",
		},
	},
	greyText: {
		color: "#929292",
	},
	follow: {
		backgroundColor: "#fff",
		borderRadius: 20,
		boxShadow: "0 3px 4px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.23)",
		zIndex: 99,
		position: "absolute",
		right: 15,
		top: 15,
		fontSize: 14,
		fontWeight: 600,
		cursor: "pointer",
		padding: "4px 20px",
		"&:hover": {
			// backgroundColor: "#3e3e3e11",
			boxShadow: "0 3px 8px rgba(0,0,0,0.16), 0 3px 8px rgba(0,0,0,0.23)",
		},
	},
	followed: {
		backgroundColor: "#3e3e3e",
		color: "#fff",
		borderRadius: 20,
		boxShadow: "0 3px 4px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.23)",
		zIndex: 99,
		position: "absolute",
		right: 15,
		top: 15,
		fontSize: 14,
		fontWeight: 600,
		cursor: "pointer",
		padding: "4px 20px",
		textAlign: "center",
		margin: 0,
		marginBottom: theme.spacing(3),
		justifyContent: "center",
		alignItems: "center",
		"&:hover": {
			// backgroundColor: "#3e3e3e11",
			boxShadow: "0 3px 8px rgba(0,0,0,0.16), 0 3px 8px rgba(0,0,0,0.23)",
		},
	},
}));
function ProductCard(props) {
	const classes = useStyles();
	const { card } = props;
	const [following, setFollowing] = useState(props.following);
	const [followers, setFollowers] = useState(props.followers);
	const [error, setError] = useState(false);

	const handleOpenEOI = (id, f) => {
		props.eoi(id, true);
	};

	useEffect(() => {
		setFollowing(props.following);
	}, [props.following]);
	useEffect(() => {
		setFollowers(props.followers);
	}, [props.followers]);

	const updateFollowing = (id, del) => {
		if (props.signedIn) {
			setFollowing(del);
			if (del) setFollowers(followers + 1);
			else setFollowers(Math.max(followers - 1, 0));
			fb.updateFollowing(id, del)
				.then((res) => {
					setError(false);
				})
				.catch((e) => {
					setError(true);
					setFollowing(!del);
					setFollowers(followers);
					console.error(e);
				});
		} else {
			props.signInModal();
		}
	};

	return (
		<React.Fragment>
			<ErrorSnackbar errorType={0} open={error} parentCallbackCloseError={() => setError(false)} />
			{following ? (
				<div className={classes.followed} onClick={() => updateFollowing(card.id, false)}>
					<DoneIcon
						fontSize="small"
						style={{
							verticalAlign: "middle",
							marginRight: 4,
							marginBottom: 2,
						}}
					/>{" "}
					following
					<span style={{ fontWeight: 500 }}> {Math.max(1, followers)}</span>
				</div>
			) : (
				<Tooltip title='Follow to add this to your "Following" list and receive price notifications' placement="top" enterDelay={1000}>
					<div className={classes.follow} onClick={() => updateFollowing(card.id, true)}>
						<AddIcon
							fontSize="small"
							style={{
								verticalAlign: "middle",
								marginRight: 4,
								marginBottom: 2,
							}}
						/>{" "}
						follow <span style={{ fontWeight: 500 }}>{followers}</span>
					</div>
				</Tooltip>
			)}
			<Link
				to={{ pathname: `/${card.id.toLowerCase()}` }}
				style={{
					textDecoration: "none",
					width: "100%",
				}}
			>
				<Card className={classes.card} id="prodContainer" elevation={0}>
					<LazyLoadImage
						effect="blur"
						id={card.id.toLowerCase()}
						className={classes.cardMedia}
						style={{
							height: props.isMobile ? 200 : 300,
							width: "100%",
						}}
						src={card.img}
						alt={card.productName}
					/>
				</Card>
			</Link>
			<Grid
				item
				xs={12}
				md={8}
				style={{
					textAlign: "left",
					fontWeight: 700,
					padding: "10px 2px",
					height: props.isMobile ? "3rem" : "4rem",
				}}
			>
				<p className="wrapText" style={{ fontSize: props.isMobile ? "0.7rem" : null }}>
					{card.productName}
				</p>
			</Grid>
			{props.mobHeader ? null : (
				<Grid
					item
					xs={6}
					md={4}
					className={classes.greyText}
					style={{
						textAlign: "right",
						fontWeight: 600,
						padding: "10px 2px",
					}}
				>
					{card.sellOffers.length} {card.sellOffers.length > 1 ? "Offers" : "Offer"}
				</Grid>
			)}
			<Grid item xs={12} md={12} style={{ display: "inline", marginBottom: 40 }}>
				<Button
					variant="contained"
					className={classes.buy}
					style={{
						fontSize: props.mobHeader ? "0.8rem" : "0.9rem",
						float: "left",
						width: "48%",
					}}
					onClick={() => handleOpenEOI(`buyMain-${card.productName}`)}
				>
					Buy {props.mobHeader ? <br /> : null} {card.lowestAsk}
				</Button>
				<Button
					variant="contained"
					className={classes.sell}
					style={{
						fontSize: props.mobHeader ? "0.8rem" : "0.9rem",
						float: "right",
						width: "48%",
					}}
					onClick={() => handleOpenEOI(`sellMain-${card.productName}`)}
				>
					Sell {props.mobHeader ? <br /> : null}
					{card.highestOffer}
				</Button>
			</Grid>
		</React.Fragment>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
	mobHeader: width < 1075,
});

export default withSizes(mapSizesToProps)(withRouter(ProductCard));
