import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const bannerContent = [
	{
		line1: "A different kind of marketplace.",
		line2: "Follow the resell prices of products you own",
	},
	{
		line1: "A different kind of marketplace.",
		line2: "Follow products to buy at the cheapest price",
	},
];

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: "100%",
		flexGrow: 1,
	},
	header: {
		display: "flex",
		alignItems: "center",
		height: 50,
		paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default,
	},
}));

function SwipeableTextMobileStepper(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = bannerContent.length;

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<div className={classes.root}>
			<AutoPlaySwipeableViews axis={"x"} index={activeStep} interval={5000} onChangeIndex={handleStepChange}>
				{bannerContent.map((step, index) => (
					<h2
						key={index}
						style={{
							fontSize: props.isMobile ? "12px" : "22px",
							lineHeight: 1.4,
							color: "#fff",
							fontWeight: 500,
						}}>
						{bannerContent[activeStep].line2}
					</h2>
				))}
			</AutoPlaySwipeableViews>
		</div>
	);
}

export default SwipeableTextMobileStepper;
