import React, { useEffect, useState } from "react";
import "./App.css";
import "typeface-nunito-sans";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Product, products } from "./product";
import { PageNotFound, Main, Signin, Signup, PageContainer, Following, Orders, Buy, Sell } from "./pages";
import { CssBaseline } from "@material-ui/core";
import withSizes from "react-sizes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactPixel } from "./components";
import { hotjar } from "react-hotjar";
import { userfb } from "./firebase";
import AuthorizedRoute from "./authorizedRoute";

hotjar.initialize(1760108, 6);
ReactPixel.pageView();

const font = "Nunito Sans, sans-serif";
const themeColor = "#35B2FF";

const lightTheme = createMuiTheme({
	"@global": {
		body: {
			fontFamily: font,
		},
	},
	typography: {
		fontFamily: font,
		fontSize: 16,
	},
	palette: {
		type: "light",
		background: {
			transition: "background-color 0.3s ease",
		},
		primary: {
			main: themeColor,
			success: "#75c24d",
		},
		secondary: {
			light: "#f6f6f8",
			main: "#ffffff",
		},
		fontFamily: font,
	},
});

function App(props) {
	const [signedIn, setSignedIn] = useState(false);
	const [authPending, setAuthPending] = useState(true);
	useEffect(() => {
		window.scrollTo(0, 0);
		userfb.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setSignedIn(true);
				setAuthPending(false);
			} else {
				setSignedIn(false);
				setAuthPending(false);
			}
		});
	}, []);

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<MuiThemeProvider theme={lightTheme}>
				<div className="App">
					<CssBaseline />
					<Router>
						<Switch>
							<Route exact path="/" render={(props) => <MainWrapped {...props} signedIn={signedIn} />} />
							<Route exact path="/signin" component={Signin} />
							<Route exact path="/signup" component={Signup} />
							<AuthorizedRoute exact path="/following" signedIn={signedIn} authPending={authPending} component={FollowingWrapped} />
							<AuthorizedRoute exact path="/orders" signedIn={signedIn} authPending={authPending} component={OrdersWrapped} />
							<AuthorizedRoute exact path="/buy" signedIn={signedIn} authPending={authPending} component={BuyWrapped} />
							<AuthorizedRoute exact path="/sell" signedIn={signedIn} authPending={authPending} component={SellWrapped} />
							{products.map((card) => (
								<Route
									exact
									key={card.id}
									path={`/${card.id}`}
									render={(props) => (
										<PageContainer
											signedIn={signedIn}
											{...props}
											page={() => (
												<Product
													products={products}
													product={card}
													others={products.filter((c) => {
														return c.productName !== card.productName;
													})}
													flag={true}
												/>
											)}
										/>
									)}
								/>
							))}
							<Route component={ErrorWrapped} />
						</Switch>
					</Router>
				</div>
			</MuiThemeProvider>
		</MuiPickersUtilsProvider>
	);
}

const MainWrapped = (props) => <PageContainer signedIn={props.signedIn} page={() => <Main products={products} />} />;
const FollowingWrapped = (props) => <PageContainer signedIn={props.signedIn} page={() => <Following products={products} />} />;
const OrdersWrapped = (props) => <PageContainer signedIn={props.signedIn} page={() => <Orders products={products} />} />;
const BuyWrapped = (props) => <PageContainer signedIn={props.signedIn} page={() => <Buy />} />;
const SellWrapped = (props) => <PageContainer signedIn={props.signedIn} page={() => <Sell />} />;
const ErrorWrapped = (props) => <PageContainer signedIn={props.signedIn} page={() => <PageNotFound />} />;

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(App);
