import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PowerSettingsNew } from "@material-ui/icons";
import { Button, Badge, ListItemIcon, Typography, Menu, MenuItem, CircularProgress } from "@material-ui/core";
import { auth } from "../firebase";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	signIn: {
		padding: "2px 20px",
		fontWeight: 600,
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4,
	},
}));

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleSignOut = () => {
		setAnchorEl(null);
		auth.signOut()
			.then((res) => {
				console.log("Sign Out Success");
			})
			.catch(console.error);
	};

	return (
		<React.Fragment>
			<Button
				className={classes.signIn}
				variant={!props.change && window.location.pathname === "/" ? null : "contained"}
				style={{ color: props.clr }}
				disableElevation
				color='primary'
				onClick={handleClick}>
				{props.initials ? props.initials : <CircularProgress size={25} style={{ color: "#fff" }} />}
			</Button>

			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 6.5,
						width: "30ch",
					},
				}}>
				<MenuItem onClick={handleSignOut}>
					<ListItemIcon>
						<PowerSettingsNew fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit' noWrap>
						Sign Out
					</Typography>
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
