import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import withSizes from "react-sizes";
import { Link, Redirect } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Button, CssBaseline, TextField, Grid, CircularProgress, Divider } from "@material-ui/core";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { ErrorSnackbar } from "../components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ImageIcon from "@material-ui/icons/Image";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
	"@global": {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "1em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
		textAlign: "center",
	},
	textField: {
		display: "inline-block",
		width: "100%",

		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInputLabel-outlined ": {
			fontSize: "1em",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "1em",
			borderRadius: "0.6em",
			"& fieldset": {
				borderColor: "#e3e3e3",
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-input": { padding: "1.1em" },
	},
	button: {
		border: "1px solid #e3e3e3",
		borderRadius: "0.6em",
		backgroundColor: "transparent",
		color: "#929292cc",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: 155,
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			borderColor: theme.palette.primary.main + "!important",
		},
	},
	input: {
		display: "none",
	},
	card: {
		minWidth: 275,
		border: "1px solid #f6f6f8",
		borderRadius: "4px",
		padding: "10px",
		marginTop: "20px",
	},

	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	paper: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "350px",
	},
}));

function Buy(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [values, setValues] = useState({
		productName: "",
		offer: 0,
		condition: "",
		location: "",
		imageUrl: "",
		imageLoading: false,
		loading: false,
		errorType: 0,
		errorOpen: false,
		errorMessage: "",
		isSignedIn: false,
	});
	const [selectedDate, handleDateChange] = useState(moment().add(7, "days"));

	const handleChange = (name) => (event) => {
		setValues({ ...values, [name]: name === "offer" ? Number(event.target.value) : _.startCase(_.toLower(event.target.value)) });
	};

	const submit = (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		if (values.imageUrl === "") setValues({ ...values, errorType: 10, errorOpen: true, loading: false });
		else if (values.productName === "") setValues({ ...values, errorType: 11, errorOpen: true, loading: false });
		else if (values.offer === 0) setValues({ ...values, errorType: 12, errorOpen: true, loading: false });
		else if (values.condition === "") setValues({ ...values, errorType: 13, errorOpen: true, loading: false });
		else if (values.location === "") setValues({ ...values, errorType: 14, errorOpen: true, loading: false });
	};

	const callbackCloseError = (param) => {
		setValues({ ...values, errorOpen: false });
	};

	const handleCapture = (e) => {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			console.log(image);
			// this.setState(() => ({ image }));
			// var fileType = image.type.slice(image.type.indexOf("/") + 1, image.type.length);
			// const uploadTask = authStorage.ref(`storeLogos/${auth.currentUser.uid}.${fileType}`).put(image);
			// uploadTask.on(
			// 	"state_changed",
			// 	snapshot => {
			// 		this.setState({ imageLoading: true });
			// 	},
			// 	error => {
			// 		// error function ....
			// 		this.setState({ imageLoading: false });
			// 		console.log(error);
			// 	},
			// 	() => {
			// 		// complete function ....
			// 		authStorage
			// 			.ref(`storeLogos/${auth.currentUser.uid}.${fileType}`)
			// 			.getDownloadURL()
			// 			.then(imageUrl => {
			// 				this.setState({
			// 					imageUrl: imageUrl,
			// 					imageLoading: false
			// 				});
			// 				this.props.handleChange("imageUrl", this.state.imageUrl);
			// 			});
			// 	}
			// );
		}
	};

	return (
		<div style={{ marginTop: "100px" }}>
			<ErrorSnackbar
				errorType={values.errorType}
				stripeError={values.errorMessage}
				open={values.errorOpen}
				parentCallbackCloseError={callbackCloseError}
			/>
			<Grid
				container
				direction='column'
				justify='flex-start'
				alignItems='center'
				spacing={0}
				style={{
					marginBottom: "1em",
					width: 500,
					margin: "auto",
				}}>
				<Fade>
					<div>
						<input accept='image/*' className={classes.input} id='contained-button-file' onChange={handleCapture} type='file' />
						<label htmlFor='contained-button-file'>
							<div
								onChange={handleCapture}
								style={{
									border: values.imageUrl !== "" ? null : values.errorType === 10 ? "1px solid #ff000088" : null,
									boxShadow: values.imageUrl !== "" ? null : values.errorType === 10 ? "0 0 2px #f00" : null,
								}}
								className={classes.button}>
								<div
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										textAlign: "center",
									}}>
									{values.imageUrl !== "" && values.imageLoading === false ? (
										<Fade>
											<img
												src={values.imageUrl}
												alt='Logo Url'
												style={{
													display: "inline-block",
													maxHeight: "150px",
													maxWidth: "300px",
													marginLeft: "auto",
													marginRight: "auto",
												}}
											/>
										</Fade>
									) : values.imageLoading === true ? (
										<div
											style={{
												left: "50%",
												top: "50%",
												marginLeft: "auto",
												marginRight: "auto",
												display: "inline-block",
											}}>
											<Lottie
												height={150}
												width={150}
												options={{
													loop: true,
													autoplay: true,
													animationData: loader.default,
													rendererSettings: {
														preserveAspectRatio: "xMidYMid slice",
													},
												}}
											/>
										</div>
									) : (
										<span style={{ textAlign: "center" }}>
											<ImageIcon style={{ fontSize: "2.4em" }} />
											<p style={{ margin: 6, fontSize: "0.9em" }}>Upload Logo</p>
										</span>
									)}
								</div>
							</div>
						</label>
						<form className={classes.form} noValidate>
							<TextField
								margin='normal'
								className={classes.textField}
								fullWidth
								error={values.errorType === 11}
								onChange={handleChange("productName")}
								label='Product Name'
								autoFocus
								variant='outlined'
							/>
							<Divider variant='middle' style={{ marginTop: "10px" }} />
							<TextField
								margin='normal'
								error={values.errorType === 12}
								className={classes.textField}
								type='number'
								fullWidth
								onChange={handleChange("offer")}
								label='Offer'
								variant='outlined'
							/>
							<TextField
								margin='normal'
								className={classes.textField}
								fullWidth
								error={values.errorType === 13}
								onChange={handleChange("condition")}
								label='Condition'
								variant='outlined'
							/>
							<TextField
								margin='normal'
								className={classes.textField}
								fullWidth
								error={values.errorType === 14}
								onChange={handleChange("location")}
								label='Location'
								variant='outlined'
							/>
							<KeyboardDatePicker
								autoOk
								fullWidth
								className={classes.textField}
								margin='normal'
								variant='inline'
								error={values.errorType === 15}
								label='Until'
								inputVariant='outlined'
								format='DD/MM/YYYY'
								minDate={moment.now()}
								value={selectedDate}
								InputAdornmentProps={{ position: "start" }}
								onChange={(date) => handleDateChange(date)}
							/>
							<Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit} onClick={submit}>
								{!values.loading ? "Add Buy Offer" : <CircularProgress size={24} style={{ color: "#fff" }} />}
							</Button>
						</form>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>
				</Fade>
			</Grid>
		</div>
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(Buy);
