import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Breadcrumbs, InputAdornment, MenuItem, Container, Tabs, Tab, Tooltip, AppBar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Link, withRouter } from "react-router-dom";
import { BuyOffersTable, SellOffersTable } from "./";
import { Twitter, Facebook, Linkedin, Mail } from "react-social-sharing";
import { Helmet } from "react-helmet";
import withSizes from "react-sizes";
import Fade from "react-reveal/Fade";
import QueryString from "query-string";
import _ from "lodash";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { fb } from "../firebase";
import { ProductCard, ErrorSnackbar, EOIModal } from "../components";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
	cardGrid: {
		paddingTop: theme.spacing(12),
		paddingBottom: theme.spacing(8),
		textAlign: "left",
		maxWidth: "1440px",
	},
	grid: {
		height: "100%",
		display: "flex",
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		backgroundColor: "#f6f6f8",
		borderRadius: "20px",
		display: "inline",
		marginRight: 8,
		padding: 8,
		fontSize: 12,
	},
	chipIcon: {
		verticalAlign: "bottom",
		fontSize: 22,
	},
	buy: {
		backgroundColor: "#58c8f5",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		height: "100%",
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#0eb1f1",
			boxShadow: "none",
		},
	},
	sell: {
		backgroundColor: "#71db80",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		height: "100%",
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#47d15a",
			boxShadow: "none",
		},
	},
	sellCol: {
		color: "#75c24d",
	},
	inputHowTo: {
		padding: "6px 0 7px",
		fontWeight: "700",
	},
	title: {
		fontSize: "1.6rem",
		fontWeight: 600,
		marginTop: 0,
		display: "inline-flex",
		marginRight: 16,
		marginBottom: 0,
	},
	greyText: {
		color: "#929292",
	},
	productTabs: {
		backgroundColor: "transparent",
	},
	tabsBar: {
		backgroundColor: "transparent",
		// borderBottom: "4px solid red"
		zIndex: 99,
	},
	productTab: {
		backgroundColor: "transparent",
	},
	productTabContent: {
		border: "1px solid #e3e3e3",
		marginTop: " -1px",
		padding: "0px 18px",
		marginBottom: "18px",
	},
	textfieldLabel: {
		fontSize: 14,
		marginBottom: 0,
	},
	inputDrop: {
		// marginLeft: " -8px",
		// padding: "10.5px 16px",
		fontWeight: "700",
		fontSize: "0.9em",
	},
	submitOffer: {
		// backgroundColor: "#000",
		color: "#fff",
		boxShadow: "none",
		fontWeight: 700,
		// lineHeight: "normal",
		"&:hover": {
			backgroundColor: "#000000aa",
			boxShadow: "none",
		},
	},

	mainFollow: {
		backgroundColor: "#fff",
		borderRadius: 20,
		border: "1px solid #e3e3e3",
		color: "#929292",
		zoom: 0.9,
		zIndex: 99,
		display: "inline-flex",
		// fontSize: 14,
		fontWeight: 600,
		marginBottom: "2em",
		cursor: "pointer",
		padding: "4px 20px",
		"&:hover": {
			backgroundColor: "#3e3e3e11",
		},
	},
	mainFollowed: {
		backgroundColor: "#3e3e3e",
		color: "#fff",
		borderRadius: 20,
		zoom: 0.9,
		border: "1px solid #e3e3e3",
		zIndex: 99,
		display: "inline-flex",
		// fontSize: 14,
		marginBottom: "2em",
		fontWeight: 600,
		cursor: "pointer",
		padding: "4px 20px",
		"&:hover": {
			backgroundColor: "#3e3e3e11",
			color: "#929292",
		},
	},
}));

function LinkTree(props) {
	return (
		<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
			<Link color="inherit" to={{ pathname: "/" }} style={{ fontSize: "1em", fontWeight: 600, textDecoration: "none", color: "#000" }}>
				Home
			</Link>
			<Typography color="textPrimary" style={{ fontSize: "1em", fontWeight: 600, textDecoration: "none" }}>
				{props.product.productName}
			</Typography>
		</Breadcrumbs>
	);
}

function shuffle(array) {
	array.sort(() => Math.random() - 0.5);
	return array;
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	);
}
function numberWithCommas(x) {
	return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ProductPage(props) {
	const classes = useStyles();
	const [offer, setOffer] = useState("");
	const [op, setOp] = useState("BUY");
	const [btn, setBtn] = useState();
	const product = props.product;
	const [related, setRelated] = useState([]);
	const [tab, setTab] = React.useState(0);
	const [selectedDate, handleDateChange] = useState(moment().add(7, "days"));
	const [selectedBuyOffer, setSelectedBuyOffer] = useState();
	const [selectedSellOffer, setSelectedSellOffer] = useState();
	const [following, setFollowing] = useState(false);
	const [followers, setFollowers] = useState(0);
	const [allFollowing, setAllFollowing] = useState({});
	const [error, setError] = useState("");

	const handleChangeTab = (event, tab) => {
		setTab(tab);
	};

	const handleChange = (name) => (event) => {
		if (name === "op") {
			setOp(event.target.value);
		} else {
			setOffer(event.target.value);
		}
	};

	const handleOpenEOI = (id, o) => {
		if (id === "mainBuyBtn") setBtn(`${id}-${selectedBuyOffer.offer}`);
		else if (id === "mainSellBtn") setBtn(`${id}-${selectedSellOffer.offer}`);
		else setBtn(id);
		setOpen(true);
	};

	useEffect(() => {
		window.scrollTo(0, 5);
		var parsed = QueryString.parse(window.location.search);
		if (parsed.eoi) {
			handleOpenEOI(`f-follow-${product.productName}`);
		}
		var sortedOthers = props.others;

		var selectedBO = product.buyOffers ? product.buyOffers.sort((a, b) => (a.offer > b.offer ? 1 : -1)) : [];
		setSelectedBuyOffer(_.isEmpty(selectedBO) ? null : selectedBO[0]);
		var selectedSO = product.sellOffers ? product.sellOffers.sort((a, b) => (a.offer < b.offer ? 1 : -1)) : [];
		setSelectedSellOffer(_.isEmpty(selectedSO) ? null : selectedSO[0]);

		sortedOthers.sort((a, b) => (a.sellOffers.length > b.sellOffers.length ? -1 : 1));
		var rel = sortedOthers.filter(function (p) {
			return p.category === product.category;
		});
		rel = shuffle(rel);
		var oth = sortedOthers.filter(function (p) {
			return p.category !== product.category;
		});
		var final = rel.concat(oth);
		setRelated(final);
	}, [props.others, product]);

	useEffect(() => {
		fb.getAllFollowing(product.id)
			.then((res) => {
				setAllFollowing(res.data);
				setFollowing(res.data && res.data[0] && res.data[0][product.id]);
				setFollowers(res.data && res.data[1] && res.data[1][product.id] ? res.data[1][product.id] : 0);
			})
			.catch(console.error);
	}, []);

	const [open, setOpen] = useState(false);

	const handleCloseEOI = () => {
		setBtn("");
		setOpen(false);
	};

	const updateFollowing = (id, del) => {
		if (props.signedIn) {
			setFollowing(del);
			if (del) setFollowers(followers + 1);
			else setFollowers(Math.max(followers - 1, 0));
			fb.updateFollowing(id, del)
				.then((res) => {
					setError(false);
				})
				.catch((e) => {
					setError(true);
					setFollowing(!del);
					if (!del) setFollowers(followers);
					else setFollowers(followers);
					console.error(e);
				});
		} else {
			props.signInModal();
		}
	};

	const options = [
		{
			value: "BUY",
			label: "BUY",
		},
		{
			value: "SELL",
			label: "SELL",
		},
	];

	var activeTab = {
		borderLeft: "1px solid #e3e3e3",
		borderRight: "1px solid #e3e3e3",
		borderTop: "1px solid #e3e3e3",
		borderRadius: "2px 2px 0px 0px",
		zIndex: 99,
	};

	return (
		<main>
			<Helmet>
				<title>{product.productName}</title>
				<link rel="canonical" href={`https://market.enteroffer.com/${product.id.toLowerCase()}`} />
				<link rel="alternate" hreflang="x-default" href={`https://market.enteroffer.com/${product.id.toLowerCase()}`} />
				<link rel="alternate" hreflang="en-us" href={`https://market.enteroffer.com/${product.id.toLowerCase()}`} />
				<meta name="title" content={product.productName} />
				<meta name="description" content={product.metaDesc} />
				<meta name="keywords" content={product.keywords} />
				<meta property="twitter:description" content={product.metaDesc} />
				<meta property="twitter:image" content={product.img} />
				<meta property="og:description" content={product.metaDesc} />
				<meta property="og:image" content={product.img} />
				<meta property="image" content={product.img} />
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:site" content="@enteroffer" />
				<meta property="twitter:image" content={product.img} />
				<meta property="twitter:title" content={product.productName} />
				<meta property="og:image" content={product.img} />
				<meta property="og:title" content={product.productName} />
				<meta property="og:url" content={`https://market.enteroffer.com/${product.id.toLowerCase()}`} />
				<meta property="al:web:url" content={`https://market.enteroffer.com/${product.id.toLowerCase()}`} />
				<meta property="al:web:should_fallback" content="true" />
			</Helmet>
			<EOIModal open={open} closeModal={handleCloseEOI} btn={btn} />
			<ErrorSnackbar errorType={0} open={error} parentCallbackCloseError={() => setError(false)} />
			<Fade>
				<Container className={classes.cardGrid} maxWidth={false}>
					<div style={{ display: "inline" }}>
						<LinkTree product={product} />
						<div style={{ float: "right" }}>
							<Twitter solid small link={window.location.href} label={`twitter-${product.id.toLowerCase()}`} />
							<Facebook solid small link={window.location.href} label={`facebook-${product.id.toLowerCase()}`} />
							<Linkedin solid small link={window.location.href} label={`linkedin-${product.id.toLowerCase()}`} />
							<Mail solid small link={window.location.href} label={`mail-${product.id.toLowerCase()}`} />
						</div>
					</div>
					<Grid container spacing={4} className={classes.grid}>
						<Grid item xs={12} md={7} sm={5}>
							<LazyLoadImage effect="blur" src={product.img} alt={product.productName} style={{ width: "100%" }} />
						</Grid>
						<Grid item xs={12} md={5} sm={7}>
							<h1 className={classes.title}>
								{product.productName} {String(product.productName).length > 30 ? <br /> : null}
							</h1>
							{following ? (
								<div className={classes.mainFollowed} onClick={() => updateFollowing(product.id, false)}>
									<DoneIcon fontSize="small" />
									following <span style={{ fontWeight: 500, marginLeft: 4 }}>{followers ? followers : 0}</span>
								</div>
							) : (
								<Tooltip title='Follow to add this to your "Following" list and receive price notifications' placement="top" enterDelay={1000}>
									<div className={classes.mainFollow} onClick={() => updateFollowing(product.id, true)}>
										<AddIcon fontSize="small" /> follow <span style={{ fontWeight: 500, marginLeft: 4 }}> {followers ? followers : 0}</span>
									</div>
								</Tooltip>
							)}
							<AppBar position="static" className={classes.tabsBar} elevation={0}>
								<Tabs className={classes.productTabs} value={tab} onChange={handleChangeTab} variant="fullWidth">
									<Tab className={classes.productTab} style={tab === 0 ? activeTab : null} label="Buy" />
									<Tab className={classes.productTab} style={tab === 1 ? activeTab : null} label="Sell" />
									<Tab className={classes.productTab} style={tab === 2 ? activeTab : null} label="+ Offer" />
								</Tabs>
							</AppBar>
							<TabPanel className={classes.productTabContent} value={tab} index={0}>
								<p style={{ fontWeight: 700 }}>
									<span className={classes.greyText}>Condition:</span> {selectedBuyOffer ? _.toUpper(selectedBuyOffer.condition) : "-"}
								</p>
								<p style={{ fontWeight: 700 }}>
									<span className={classes.greyText}>Location:</span> {selectedBuyOffer ? selectedBuyOffer.location : "-"}
								</p>
								<div style={{ textAlign: "center", maxWidth: "fit-content" }}>
									<Button
										variant="contained"
										disabled={selectedBuyOffer ? false : true}
										className={classes.buy}
										onClick={() => handleOpenEOI("mainBuyBtn")}
									>
										Buy {selectedBuyOffer ? numberWithCommas(selectedBuyOffer.offer) : "-"}
									</Button>
								</div>
								{product.desc ? (
									<div>
										<h4>Description</h4>
										<p>{product.desc}</p>
									</div>
								) : null}
								<BuyOffersTable
									buyOffers={product.buyOffers}
									openEOI={(o) => handleOpenEOI(`tableBuyBtn-${o.offer}`)}
									open={open}
									selected={(o) => setSelectedBuyOffer(o)}
								/>
							</TabPanel>
							<TabPanel className={classes.productTabContent} value={tab} index={1}>
								<p style={{ fontWeight: 700, textAlign: "right" }}>
									<span className={classes.greyText}>Condition:</span> {selectedSellOffer ? _.toUpper(selectedSellOffer.condition) : "-"}
								</p>
								<p style={{ fontWeight: 700, textAlign: "right" }}>
									<span className={classes.greyText}>Location:</span> {selectedSellOffer ? selectedSellOffer.location : "-"}
								</p>
								<div
									style={{
										textAlign: "center",
										maxWidth: "fit-content",
										margin: "auto",
										marginRight: 0,
									}}
								>
									<Button
										variant="contained"
										disabled={selectedSellOffer ? false : true}
										className={classes.sell}
										onClick={() => handleOpenEOI("mainSellBtn")}
									>
										Sell {selectedSellOffer ? numberWithCommas(selectedSellOffer.offer) : null}
									</Button>
								</div>
								{product.desc ? (
									<div>
										<h4>Description</h4>
										<p>{product.desc}</p>
									</div>
								) : null}
								<SellOffersTable
									sellOffers={product.sellOffers}
									openEOI={(o) => handleOpenEOI(`tableSellBtn-${o.offer}`)}
									open={open}
									selected={(o) => setSelectedSellOffer(o)}
								/>
							</TabPanel>
							<TabPanel className={classes.productTabContent} value={tab} index={2}>
								<div style={{ textAlign: props.isMobile ? "center" : null }}>
									<p className={classes.textfieldLabel}>Im looking to</p>
									<TextField
										select
										margin="dense"
										InputProps={{
											classes: {
												input: classes.inputDrop,
											},
										}}
										value={op}
										onChange={handleChange("op")}
										variant="outlined"
										fullWidth
									>
										{options.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
									<br />
									<br />
									<p className={classes.textfieldLabel}>For</p>
									<TextField
										margin="dense"
										variant="outlined"
										id="offer"
										style={{ width: "100%", marginRight: 8 }}
										value={offer}
										fullWidth={props.isMobile ? true : null}
										onChange={handleChange("offer")}
										InputProps={{
											type: "number",
											pattern: "[0-9]*",
											inputMode: "numeric",
											step: "1",
											classes: {
												input: classes.inputDrop,
											},
											startAdornment: (
												<InputAdornment position="start">
													<span
														style={{
															color: "#929292",
															fontWeight: "700",
														}}
													>
														$
													</span>
												</InputAdornment>
											),
										}}
									/>
									<br />
									<br />
									<p className={classes.textfieldLabel}>Until</p>

									<KeyboardDatePicker
										autoOk
										fullWidth
										margin="dense"
										variant="inline"
										inputVariant="outlined"
										format="DD/MM/YYYY"
										minDate={moment.now()}
										value={selectedDate}
										InputAdornmentProps={{ position: "start" }}
										onChange={(date) => handleDateChange(date)}
									/>
									<br />
									<br />
									{props.isMobile ? <br /> : null}
									{props.isMobile ? <br /> : null}
									<Button
										variant="contained"
										fullWidth
										className={classes.submitOffer}
										style={{ backgroundColor: op === "BUY" ? "#58c8f5" : "#71db80" }}
										onClick={() => handleOpenEOI(`submitOfferBtn-${offer}-${op}`)}
									>
										Submit Offer
									</Button>
									<br />
									<br />
								</div>
							</TabPanel>
						</Grid>
					</Grid>
					<br />
					<br />
					<h3 style={{ textAlign: "center" }}>All Products</h3>
					<Grid container spacing={2}>
						{related.map((card) => (
							<Grid item container key={card.id.toLowerCase()} xs={6} sm={4} md={3} style={{ textAlign: "center", position: "relative" }}>
								<ProductCard
									card={card}
									eoi={handleOpenEOI}
									followers={allFollowing && allFollowing[1] && allFollowing[1][card.id] ? allFollowing[1][card.id] : 0}
									following={allFollowing && allFollowing[0] && allFollowing[0][card.id] ? true : false}
									signInModal={props.signInModal}
									signedIn={props.signedIn}
								/>
							</Grid>
						))}
					</Grid>
				</Container>
			</Fade>
		</main>
	);
}
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600,
});

export default withSizes(mapSizesToProps)(withRouter(ProductPage));
