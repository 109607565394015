import React, { useState } from "react";
import "../App.css";
import "typeface-nunito-sans";
import { makeStyles } from "@material-ui/core/styles";
import { HelpModal } from "./";
import { Grid, Toolbar } from "@material-ui/core";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const useStyles = makeStyles(theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(6),
		maxWidth: "1440px"
	},
	header: {
		// backgroundColor: "#5ba7d1",
		padding: "4px 0px",
		flexGrow: 1
	},
	headerToolbar: {
		padding: "8px 20px",
		fontSize: "25px"
	},
	toolbar: {
		alignItems: "center",
		justifyContent: "space-between",
		margin: "auto",
		maxWidth: "1440px"
	},
	button: {
		color: "#656565",
		marginLeft: "1em"
	},
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
		// textAlign: "left"
	},
	navLinks: {
		textTransform: "capitalize",
		padding: "0.5rem 1rem",
		transition: "0.3s",
		marginRight: "35px",
		"&:hover": {
			color: "#000 !important",
			backgroundColor: "transparent !important"
		}
	},
	signIn: {
		padding: "2px 20px",
		fontWeight: 600,
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4
	}
}));

export default function Footer(props) {
	const classes = useStyles();

	const [openTerms, setOpenTerms] = useState(false);
	const [openPrivacy, setOpenPrivacy] = useState(false);
	const handleClickOpenTerms = () => {
		setOpenTerms(true);
	};

	const handleClickOpenPrivacy = () => {
		setOpenPrivacy(true);
	};
	const handleCloseModal = () => {
		setOpenPrivacy(false);
		setOpenTerms(false);
	};
	return (
		<footer>
			<HelpModal openTerms={openTerms} openPrivacy={openPrivacy} handleClose={handleCloseModal} />
			<div
				style={{
					backgroundColor: "#2b2b2b",
					color: "#FFF",
					width: "100vw",
					marginLeft: "calc(-50vw + 50%)"
				}}>
				<Grid
					container
					direction='row'
					justify='flex-start'
					alignItems='flex-start'
					style={{
						padding: "1em 2em",
						maxWidth: "1440px",
						textAlign: "left",
						margin: "auto",
						fontSize: "13.5px"
					}}>
					<Grid item xs={6} md={2}>
						<p>
							<b>ENTEROFFER</b>
						</p>
						<p style={{ lineHeight: "1.8", opacity: "0.7" }}>
							<a
								href='https://enteroffer.com/about'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								About Us
							</a>
							<br />
							<span style={{ cursor: "pointer" }} onClick={handleClickOpenTerms}>
								Term of Use
							</span>
							<br />
							<span style={{ cursor: "pointer" }} onClick={handleClickOpenPrivacy}>
								Privacy Policy
							</span>
							<br />
						</p>
					</Grid>
					<Grid item xs={6} md={2}>
						<p>
							<b>HELP & SUPPORT</b>
						</p>
						<p style={{ lineHeight: "1.8", opacity: "0.7" }}>
							<a
								href='https://enteroffer.com/faq'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								FAQ
							</a>
							<br />
							<a
								href='https://enteroffer.com/#contactUs'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								Contact Us
							</a>
							<br />
						</p>
					</Grid>

					<Grid item xs={6} md={2}>
						<p>
							<b>FOLLOW US</b>
						</p>
						<p style={{ lineHeight: "1.8", opacity: "0.7" }}>
							<a
								href='https://www.instagram.com/enteroffer/'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								Instagram
							</a>
							<br />
							<a
								href='https://www.facebook.com/enteroffer/'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								Facebook
							</a>
							<br />
							<a
								href='https://twitter.com/EnterOffer'
								target='_blank'
								rel='noopener noreferrer'
								style={{
									textDecoration: "none",
									color: "#FFF"
								}}>
								Twitter
							</a>
							<br />
						</p>
					</Grid>
				</Grid>
			</div>

			<div
				style={{
					backgroundColor: "#F6F6F8",
					width: "100vw",
					marginLeft: "calc(-50vw + 50%)",
					padding: "0px -8px -8px 8px"
				}}>
				<Toolbar className={classes.toolbar}>
					<div>
						<a href='https://enteroffer.com/'>
							<LazyLoadImage
								effect='blur'
								src={"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"}
								alt='enterOffer'
								style={{
									width: props.isMobile ? "7em" : "10em",
									display: "block",
									marginLeft: "auto",
									marginRight: "auto"
								}}
							/>
						</a>
					</div>
					<div>
						<p
							className={classes.button}
							style={{
								fontWeight: 400,
								fontSize: props.isMobile ? "0.7em" : null
							}}>
							COPYRIGHT &copy; {moment().year()} EnterOffer
						</p>
					</div>
				</Toolbar>
			</div>
		</footer>
	);
}
